import { useRef, useState } from "react"
import DropdownInput from "../common/components/forms/inputs/DropdownInput"
import { Box, Button, Grid, Typography } from "@mui/material"
import TextInput from "../common/components/forms/inputs/TextInput"

import { renderAddButton } from "./FormForm"
const DropDownOptions = ({ index: inputIndex, register, updateOptions }) => {
  const [options, setOptions] = useState([""]) // Initialize options state with an empty string

  const handleAddOption = () => {
    setOptions([...options, ""]) // Add a new empty option to the options array
  }

  const handleOptionChange = (value, optionIndex) => {
    const newOptions = [...options] // Copy the options array
    newOptions[optionIndex] = value // Update the option at the specified index
    setOptions(newOptions) // Update the options state
    updateOptions(newOptions) // Call the updateOptions function to update the parent state
  }

  return (
    <Grid item xs={12}>
      <Box px={2}>
        <Grid container my={2} alignItems="center">
          <Grid item xs={6}>
            <Typography color="white.main" variant="body1">
              Dropdown options
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {renderAddButton(handleAddOption, 25)}
          </Grid>
        </Grid>

        {options.map((option, optionIndex) => (
          <Box key={optionIndex} mb={2}>
            <TextInput
              label={`Option ${optionIndex + 1}`}
              name={`inputs_${inputIndex}_options_${optionIndex}`}
              value={option}
              onChange={(e) => handleOptionChange(e.target.value, optionIndex)}
              register={register}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  )
}

const FormColumnInformation = ({ index, register }) => {
  const [isDropdown, setIsDropdown] = useState(false)

  const handleInputTypeOnChange = (value) => {
    if (value === "Dropdown") {
      setIsDropdown(true)
    }
  }

  return (
    <Grid container py={1} spacing={1}>
      <Grid item xs={4}>
        <DropdownInput
          label="Type"
          name={`type_${index}`}
          options={[
            "Checkbox",
            "Date",
            "DateTime",
            "Directory",
            "Dropdown",
            "Image",
            "Images",
            "PhoneNumber",
            "PostalCode",
            "Text",
            "Time",
          ]}
          onChange={handleInputTypeOnChange}
          register={register}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput label="Name" name={`name_${index}`} register={register} />
      </Grid>
      <Grid item xs={4}>
        <TextInput label="Label" name={`label_${index}`} register={register} />
      </Grid>

      {isDropdown && <DropDownOptions index={index} register={register} />}
    </Grid>
  )
}

export default FormColumnInformation
