import React from "react"
import { Box, Typography, Container, IconButton } from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useNavigate } from "react-router"

export default function AboutPage() {
  const navigate = useNavigate()
  return (
    <>
      <Box m={2} sx={{ position: "fixed", left: "0", top: "0" }}>
        <IconButton color="white" onClick={() => navigate("/")}>
          <ArrowBackIosIcon />
        </IconButton>
      </Box>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "24px",

            borderRadius: "12px",
            textAlign: "center",
            maxWidth: "500px",
          }}
        >
          <Typography color="white.main" variant="h4" gutterBottom>
            Tron is product made by Technologies Codebot inc.
          </Typography>
          <Typography color="white.main" variant="body1">
            Jonathan Girard <br />
            173 rue St-Louis, Sainte-Brigitte-De-Laval, <br />
            Qc, Canada <br />
            G0A3K0
          </Typography>
          <Typography
            color="white.main"
            variant="body2"
            sx={{ marginTop: "16px" }}
          >
            Contact us at: jonathan.girard@technologiescodebot.com
          </Typography>
        </Box>
      </Container>
    </>
  )
}
