var socket

const TronWebsocket = {
  init: (handleAction) => {
    if (process.env.NODE_ENV === "development") {
      socket = new WebSocket(`ws://${process.env.REACT_APP_TRON_WEBSOCKET_URL}`)
    } else {
      socket = new WebSocket(
        `wss://${process.env.REACT_APP_TRON_WEBSOCKET_URL}`
      )
    }

    socket.onopen = (event) => {
      console.log("Websocket connection open")
    }
    socket.onmessage = (event) => {
      console.log("onmessage")
      handleAction(JSON.parse(event.data))
    }
    socket.onclose = function (event) {
      console.log("WebSocket connection closed")
      socket = null
    }
  },
  close: async () => await socket.close(),
  send: (command) => socket.send(command),
  isNotConnected: () => socket === undefined,
}

export default TronWebsocket
