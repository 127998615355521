import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  Card,
  CardActions,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useRxCollection } from "rxdb-hooks"
import TextInput from "../common/components/forms/inputs/TextInput"
import { PINK, PRIMARY_MAIN } from "../themes/theme"
import {
  CARDS_COLOR,
  CARDS_LIGHT,
  SECONDARY_MAIN,
  TEXT_MAIN,
} from "../common/layout/theme"
import AddIcon from "@mui/icons-material/Add"
import { useContext, useEffect, useState } from "react"
import AzureCloudConnectionForm from "./AzureCloudConnectionForm"
import AzureContainerAppForm from "./AzureContainerAppForm"
import DockerHubSearch from "./DockerHubSearch"

import { useParams } from "react-router"
import { createUniqueId } from "../common/utils/utilities"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import TerraformCloudConnectionForm from "./TerraformCloudConnectionForm"
import GithubSelectBranch from "./github/GithubSelectBranch"

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "card.light",
  border: "1px solid " + PINK,
  boxShadow: 24,
  p: 4,
}

const DeploymentForm = ({ closeForm, onComplete, repository }) => {
  let { id } = useParams()

  const [isAzureCloudConnectionFormOpen, setIsAzureCloudConnectionFormOpen] =
    useState()
  const [isAzureContainerAppFormOpen, setIsAzureContainerAppFormOpen] =
    useState()
  const [
    isTerraformCloudConnectionFormOpen,
    setIsTerraformCloudConnectionFormOpen,
  ] = useState()
  const [isTerraformCloud, setIsTerraformCloud] = useState(false)
  const [terraformCloudConnection, setTerraformCloudConnection] = useState()
  const [
    isAzureKubernetesServiceFormOpen,
    setIsAzureKubernetesServiceFormOpen,
  ] = useState()
  const [isAks, setIsAks] = useState(false)
  const [isContainerApp, setIsContainerApp] = useState(false)
  const [azureCloudConnectionId, setAzureCloudConnectionId] = useState()
  const [isGithubActionsSelected, setIsGithubActionsSelected] = useState()
  const [deployImage, setDeployImage] = useState()
  const [selectedImage, setSelectedImage] = useState()

  const [selectedBranch, setSelectedBranch] = useState()

  //const repositorySplit = repository.split("/")
  //const repositoryOwner = repositorySplit[0]
  //const repositoryName = repositorySplit[1]

  const { register, getValues } = useForm()

  const handleOnSubmit = async () => {
    let data = getValues()

    const requestData = {
      ...data,
      // domainName: "tron.bot", need to fetch random url from container apps
      public_image_name: selectedImage ? selectedImage.name : "",
      azure_cloud_connection_id: azureCloudConnectionId,
      project_id: id,
      is_github_actions: isGithubActionsSelected,
    }

    // add new public_image to schema
    //await collection.insert({
    //  ...requestData,
    //  id: createUniqueId(),
    //  updatedAt: new Date().getTime(),
    //})

    onComplete(requestData)
    closeForm()

    // const deployment = await fetch(
    //   process.env.REACT_APP_TRON_URL + "/deployment",
    //   {
    //     method: "POST",
    //     body: JSON.stringify(requestData),
    //   }
    // ).then((response) => response.json())
  }

  const renderAddButton = (onClick, size) => (
    <Card sx={{ width: size, height: size, backgroundColor: CARDS_COLOR }}>
      <CardActions
        sx={{
          padding: 0,
          minWidth: size,
          height: size,
          backgroundColor: CARDS_COLOR,
          display: "flex",
          justifyContent: "center", // Center horizontally
        }}
      >
        <IconButton
          sx={{
            textAlign: "center",
            backgroundColor: CARDS_COLOR,
          }}
          color="text"
          size="small"
          edge="start"
          aria-label="menu"
          onClick={() => onClick(true)}
        >
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  )

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const [tabIndex, setTabIndex] = useState(0)

  const isImageAlreadyBuilt = () => {
    return tabIndex === 0
  }

  const handleTabIndexChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  const handleSetSelectedImage = (image) => {
    setSelectedImage(image)
  }

  const handleContainerAppCompleted = () => {
    setIsContainerApp(true)
    setIsAks(false)
    // setContainerAppConfiguration(data) obsolete: now using parent register
  }

  const handleAksCompleted = () => {
    setIsContainerApp(false)
    setIsAks(true)
    // setContainerAppConfiguration(data)
  }

  const handleTerraformCloudConnectionCompleted = (
    terraformCloudConnection
  ) => {
    setIsTerraformCloud(true)
    setTerraformCloudConnection(terraformCloudConnection)
  }

  return (
    <Grid container>
      <Grid container px={4} alignItems="center">
        <Grid item xs={10} py={3}>
          <Typography color={PRIMARY_MAIN} variant="h6">
            Create a Deployment Environment
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton size="large" onClick={closeForm}>
            <CloseIcon fontSize="inherit" color="white" />
          </IconButton>
        </Grid>

        {/* <Grid item xs={12} py={2}>
            <Box
              borderRadius="10px"
              border={`solid 1px ${PRIMARY_MAIN}20`}
              p={3}
              pt={2}
            >
              <Typography color={PRIMARY_MAIN} variant="h6">
                Source
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Box>
                    <Box py={1}>
                      <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        value={tabIndex}
                        onChange={handleTabIndexChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Docker Hub"
                          style={{
                            color: tabIndex != 0 ? TEXT_MAIN : PINK,
                          }}
                        />
                        <Tab
                          label="Github"
                          style={{
                            color: tabIndex != 1 ? TEXT_MAIN : PINK,
                          }}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabIndex} index={0}>
                      <DockerHubSearch
                        setImage={setDeployImage}
                        setSelectedImage={handleSetSelectedImage}
                        name="docker_hub_image"
                        control={control}
                      />
                      <List
                        sx={{
                          bgcolor: SECONDARY_MAIN,
                          padding: 0,
                        }}
                      >
                        {selectedImage && (
                          <>
                            <Box
                              sx={{
                                border: "solid 1px ".concat(PINK),
                                bgcolor: SECONDARY_MAIN,
                              }}
                            >
                              <ListItem key={selectedImage.name}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      variant="body2"
                                      color="text.main"
                                    >
                                      {selectedImage.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Box>
                                      <CheckIcon color="success" />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box>{selectedImage.description}</Box>
                              </ListItem>
                            </Box>
                          </>
                        )}
                      </List>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={1}></CustomTabPanel>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box p={2}>
                    <DropdownInput
                      label="Branches"
                      name="branch"
                      options={branches}
                      defaultValue="na"
                      register={register}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}

        {/*<Grid item xs={12} mt={2}>
          <GithubSelectBranch
            register={register}
            repositoryOwner={repositoryOwner}
            repositoryName={repositoryName}
            onChange={(value) => setSelectedBranch(value)}
          />
        </Grid>
        <Grid item xs={12} mb={4}>
          <Box>
            <TextInput
              inputType="Text"
              label="Environment Name"
              name="environment_name"
              register={register}
            />
          </Box>
        </Grid>
        */}

        <Grid item xs={12} mb={2}>
          <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
            Cloud Provider
          </Typography>

          <Grid container>
            <Grid item xs={4} my={2}>
              <Card
                sx={{
                  maxWidth: 110,
                  maxHeight: 110,
                  backgroundColor: azureCloudConnectionId
                    ? CARDS_LIGHT
                    : CARDS_COLOR,
                  cursor: "pointer",
                  border: azureCloudConnectionId
                    ? `2px solid ${PINK}`
                    : "2px solid transparent",
                }}
                onClick={() => setIsAzureCloudConnectionFormOpen(true)}
              >
                <CardActions
                  sx={{
                    padding: 0,
                    minWidth: 110,
                    height: 110,
                    backgroundColor: CARDS_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#282828",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid container justifyContent="center">
                    <Grid item>
                      <img
                        src="./../images/azure-logo.png"
                        alt="github"
                        width="40px"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        color="text.main"
                        variant="subtitle2"
                        sx={{ textAlign: "center" }}
                      >
                        Azure
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              <Modal
                open={isAzureCloudConnectionFormOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={ModalStyle}>
                  <AzureCloudConnectionForm
                    setIsOpen={setIsAzureCloudConnectionFormOpen}
                    setValue={setAzureCloudConnectionId}
                  />
                </Box>
              </Modal>
            </Grid>

            {!isImageAlreadyBuilt() && (
              <>
                <Grid item xs={12} my={2}>
                  <Box>
                    <TextInput
                      inputType="Text"
                      label="Azure Container Registry Name"
                      name="container_registry_name"
                      register={register}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} my={2}>
                  <Box>
                    <TextInput
                      inputType="Text"
                      label="Container Registry Resource Group Name"
                      name="registry_resource_group_name"
                      register={register}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
            Service
          </Typography>

          <Grid container>
            <Grid item xs={4} my={2}>
              <Card
                sx={{
                  maxWidth: 110,
                  maxHeight: 110,
                  backgroundColor: isContainerApp ? CARDS_LIGHT : CARDS_COLOR,
                  cursor: "pointer",
                  border: isContainerApp
                    ? `2px solid ${PINK}`
                    : "2px solid transparent",
                }}
                onClick={() => setIsAzureContainerAppFormOpen(true)}
              >
                <CardActions
                  sx={{
                    padding: 0,
                    minWidth: 110,
                    height: 110,
                    backgroundColor: CARDS_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#282828",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                      <img
                        src="/images/azure-container-apps-logo.png"
                        alt="Container Apps Logo"
                        width="40px"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        color="text.main"
                        variant="body2"
                        sx={{ textAlign: "center" }}
                      >
                        Container App
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              <Modal
                open={isAzureContainerAppFormOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={ModalStyle}>
                  <AzureContainerAppForm
                    register={register}
                    setIsOpen={setIsAzureContainerAppFormOpen}
                    onComplete={handleContainerAppCompleted}
                  />
                </Box>
              </Modal>
            </Grid>

            <Grid item xs={4} my={2}>
              <Card
                sx={{
                  maxWidth: 110,
                  maxHeight: 110,
                  backgroundColor: isAks ? CARDS_LIGHT : CARDS_COLOR,
                  cursor: "pointer",
                  border: isAks ? `2px solid ${PINK}` : "2px solid transparent",
                }}
                onClick={() => setIsAzureKubernetesServiceFormOpen(true)}
              >
                <CardActions
                  sx={{
                    padding: 0,
                    minWidth: 110,
                    height: 110,
                    backgroundColor: CARDS_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#282828",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid container justifyContent="center" spacing={0}>
                    <Grid item>
                      <img
                        src="/images/aks-logo.png"
                        alt="Kubernetes Cluster"
                        width="40px"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        color="text.main"
                        variant="body2"
                        sx={{ textAlign: "center", lineHeight: "normal" }}
                      >
                        Azure Kubernetes Service
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              <Modal
                open={isAzureKubernetesServiceFormOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={ModalStyle}>
                  <AzureContainerAppForm
                    setIsOpen={setIsAzureKubernetesServiceFormOpen}
                    onComplete={handleAksCompleted}
                  />
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography color={PRIMARY_MAIN} variant="h6">
            Deployment Settings
          </Typography>
        </Grid>

        <Grid container my={2}>
          <Grid item xs={4}>
            <Card
              sx={{
                width: 110,
                height: 110,
                backgroundColor: isGithubActionsSelected
                  ? CARDS_LIGHT
                  : CARDS_COLOR,
                cursor: "pointer",
                border: isGithubActionsSelected
                  ? `2px solid ${PINK}`
                  : "2px solid transparent",
              }}
              onClick={() =>
                setIsGithubActionsSelected(!isGithubActionsSelected)
              }
            >
              <CardActions
                sx={{
                  padding: 0,
                  minWidth: 110,
                  height: 110,
                  backgroundColor: CARDS_COLOR,
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#282828",
                    cursor: "pointer",
                  },
                }}
              >
                <Grid container justifyContent="center">
                  <Grid item>
                    <img
                      src="./../images/github-mark.png"
                      alt="github"
                      width="40px"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      color="text.main"
                      variant="subtitle2"
                      sx={{ textAlign: "center" }}
                    >
                      Github Actions
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{
                width: 110,
                height: 110,
                backgroundColor: CARDS_COLOR,
                cursor: "pointer",
                border: isTerraformCloudConnectionFormOpen
                  ? `2px solid ${PINK}`
                  : "2px solid transparent",
              }}
              onClick={() =>
                setIsTerraformCloudConnectionFormOpen(
                  !isTerraformCloudConnectionFormOpen
                )
              }
            >
              <CardActions
                sx={{
                  padding: 0,
                  minWidth: 110,
                  height: 110,
                  backgroundColor: isTerraformCloud ? CARDS_LIGHT : CARDS_COLOR,
                  display: "flex",
                  justifyContent: "center",
                  border: isTerraformCloud
                    ? `2px solid ${PINK}`
                    : "2px solid transparent",
                  "&:hover": {
                    backgroundColor: "#282828",
                    cursor: "pointer",
                  },
                }}
              >
                <Grid container justifyContent="center">
                  <Grid item>
                    <img
                      src="./../images/terraform.png"
                      alt="github"
                      width="40px"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      color="text.main"
                      variant="subtitle2"
                      sx={{ textAlign: "center" }}
                    >
                      Terraform Cloud
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            <Modal
              open={isTerraformCloudConnectionFormOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <TerraformCloudConnectionForm
                  setIsOpen={setIsTerraformCloudConnectionFormOpen}
                  onComplete={handleTerraformCloudConnectionCompleted}
                />
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        my={2}
        px={2}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: CARDS_COLOR,
          width: "100%",
          zIndex: "10001",
        }}
      >
        <Button
          sx={{ width: "100%" }}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleOnSubmit}
        >
          Create
        </Button>
      </Grid>
    </Grid>
  )
}

export default DeploymentForm
