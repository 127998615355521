import { addRxPlugin, createRxDatabase } from "rxdb"
import { getRxStoragePouch, addPouchPlugin } from "rxdb/plugins/pouchdb"
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode"
import schemas from "./schemas"
import {
  // pullQueryBuilderFromRxSchema,
  // pullStreamBuilderFromRxSchema,
  // pushQueryBuilderFromRxSchema,
  RxDBReplicationGraphQLPlugin,
} from "rxdb/plugins/replication-graphql"

import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder"
import { RxDBAttachmentsPlugin } from "rxdb/plugins/attachments"

addPouchPlugin(require("pouchdb-adapter-idb"))
addRxPlugin(RxDBAttachmentsPlugin)
addRxPlugin(RxDBReplicationGraphQLPlugin)
addRxPlugin(RxDBDevModePlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

export var db

const initialize = async () => {
  db = await createRxDatabase({
    name: "placeholder",
    storage: getRxStoragePouch("idb"),
    ignoreDuplicate: true,
  })

  schemas.forEach(async (schema) => {
    await db.addCollections(schema)
  })

  return db
}

export default initialize
