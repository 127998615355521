const RouterProvider = {
  imports: `import RouterProvider from './router/RouterProvider'\nimport router from "./router/Router"`,
  html: (isBeginning, indentations) =>
    `${[...Array(indentations).keys()].reduce((sum) => sum + " ", "")}`.concat(
      isBeginning ? "<RouterProvider router={router()}>" : "</RouterProvider>"
    ),
  name: "RouterProvider",
  template: () => component(),
}

const component =
  () => `import { RouterProvider as ReactRouterProvider } from "react-router-dom"
import router from "./Router"

const RouterProvider = (props) => {
  return (
    <ReactRouterProvider router={router()}>
      { props.children }
    </ReactRouterProvider>
  )
}

export default RouterProvider
`

export default RouterProvider
