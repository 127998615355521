// add name to project in dispatch action
const Manifest = {
  template: (name) => `{
  "$schema": "https://json.schemastore.org/web-manifest-combined.json",
  "name": "${name}",
  "short_name": "${name}",
  "start_url": ".",
  "display": "standalone",
  "background_color": "#0000FF",
  "description": "An app called ${name}.",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "logo.png",
      "type": "image/png",
      "sizes": "192x192"
    },
  ]
}`,
}

export default Manifest
