import { writeFile } from "./FileSystemService"

const getComponentName = (lines) => {
  const findDefaultExportIndex = lines.findIndex((line) =>
    line.includes("export default")
  )
  const defaultExportLine = lines[findDefaultExportIndex]
  const splitedDefaultExportLine = defaultExportLine.split(" ")
  const ComponentName = splitedDefaultExportLine.pop()
  const cleanComponentName = ComponentName.replace(";", "")
  return cleanComponentName
}

const lineBreaks = "\n"

export const addWrappingComponentToComponent = async (
  fileHandle,
  componentToAdd
) => {
  const file = await fileHandle.getFile()
  const contents = await file.text()
  const lines = contents.split("\n")

  // for html, we exclude/seperate imports lines
  const lastImportIndex = lines.findLastIndex((line) =>
    line.includes("import ")
  )

  // excluding import lines
  const importsLines = lines.slice(0, lastImportIndex + 1)
  const theRestLines = lines.slice(lastImportIndex + 1, lines.length)

  // find the real component main function / name by searching default export
  const componentName = getComponentName(theRestLines)

  // finding main function section
  const mainFunctionStartIndex = theRestLines.findIndex((line) =>
    line.includes(componentName)
  )
  const findLastClosingBracketIndex = theRestLines.findLastIndex((line) =>
    line.includes("}")
  )

  const upperRest = theRestLines.slice(0, mainFunctionStartIndex)
  const mainFunctionContent = theRestLines.slice(
    mainFunctionStartIndex,
    findLastClosingBracketIndex + 1
  )
  const lowerRest = theRestLines.slice(
    findLastClosingBracketIndex + 1,
    theRestLines.length
  )

  const lastReturnIndex = mainFunctionContent.findLastIndex((line) =>
    line.includes("return")
  )
  const lastReturnClosingIndex = mainFunctionContent.findLastIndex((line) =>
    line.includes(")")
  )
  const mainFunctionUpperPart = mainFunctionContent.slice(
    0,
    lastReturnIndex + 1
  )
  const htmlSection = mainFunctionContent.slice(
    lastReturnIndex + 1,
    lastReturnClosingIndex
  )
  const mainFunctionLowerRest = mainFunctionContent.slice(
    lastReturnClosingIndex,
    mainFunctionContent.length
  )

  if (!importsLines.includes(componentToAdd.imports))
    importsLines.splice(importsLines.length, 0, componentToAdd.imports)

  const whiteSpaceRegex = /\S|$/
  const htmlIndentations = htmlSection.map((line) =>
    line.search(whiteSpaceRegex)
  )

  const indentedHtmlSection = htmlSection.map((line) => "  ".concat(line))
  indentedHtmlSection.splice(
    0,
    0,
    componentToAdd.html(true, htmlIndentations[0])
  )
  indentedHtmlSection.push(componentToAdd.html(false, htmlIndentations[0]))

  const toSave = [
    ...importsLines,
    ...upperRest,
    ...mainFunctionUpperPart,
    ...indentedHtmlSection,
    ...mainFunctionLowerRest,
    ...lowerRest,
  ]
  const linesWithBreakLine = toSave.map((line) => line + lineBreaks)
  const cleanedUpLines = linesWithBreakLine.join("")

  await writeFile(fileHandle, cleanedUpLines)

  return `I wrapped your component with your new component`
}

export const addComponentToSourceComponent = async (
  fileHandle,
  componentToAdd
) => {
  debugger
  const file = await fileHandle.getFile()
  const contents = await file.text()
  const lines = contents.split("\n")

  // for html, we exclude/seperate imports lines
  const lastImportIndex = lines.findLastIndex((line) =>
    line.includes("import ")
  )

  // excluding import lines
  const importsLines = lines.slice(0, lastImportIndex + 1)
  const theRestLines = lines.slice(lastImportIndex + 1, lines.length)

  // find the real component main function / name by searching default export
  const componentName = getComponentName(theRestLines)

  // finding main function section
  const mainFunctionStartIndex = theRestLines.findIndex((line) =>
    line.includes(componentName)
  )
  const findLastClosingBracketIndex = theRestLines.findLastIndex((line) =>
    line.includes("}")
  )

  const upperRest = theRestLines.slice(0, mainFunctionStartIndex)
  const componentHtml = theRestLines.slice(
    mainFunctionStartIndex,
    findLastClosingBracketIndex + 1
  )
  const lowerRest = theRestLines.slice(
    findLastClosingBracketIndex + 1,
    theRestLines.length
  )

  // indentations
  const whiteSpaceRegex = /\S|$/
  const htmlIndentations = componentHtml.map((line) =>
    line.search(whiteSpaceRegex)
  )
  const maxIndentation = Math.max(...htmlIndentations)
  const htmlToAddIndex = htmlIndentations.findIndex(
    (linesIndentation) => linesIndentation === maxIndentation
  )

  if (!importsLines.includes(componentToAdd.imports))
    importsLines.splice(importsLines.length, 0, componentToAdd.imports)

  // solidify addIndex logic
  componentHtml.splice(
    htmlToAddIndex,
    0,
    componentToAdd.html(true, htmlIndentations[htmlToAddIndex])
  )

  const toSave = [...importsLines, ...upperRest, ...componentHtml, ...lowerRest]
  const linesWithBreakLine = toSave.map((line) => line + lineBreaks)
  const cleanedUpLines = linesWithBreakLine.join("")

  await writeFile(fileHandle, cleanedUpLines)

  // const MaterialUIRequiredPackages = ['@mui/material', '@emotion/react', '@emotion/styled']
  // MaterialUIRequiredPackages.forEach(packageName => addPackage(packageName))
  return `I added element to Component`
}
