import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import { useRxCollection } from "rxdb-hooks"
import TextInput from "../common/components/forms/inputs/TextInput"
import ImageInput from "../common/components/forms/inputs/ImageInput"
import DirectoryInput from "../common/components/forms/inputs/DirectoryInput"

const ProjectForm = ({ onClose }) => {
  const collection = useRxCollection("project")

  const { control, register, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    // Here we have decision to make
    // 1. Do we store the data to Terraform Cloud
    // 2. Do we store the data our the database and/or
    // and
    // Tron does not support custom api calls.
    // I guess we would need a selection on forms, database/or api

    // here's 1
    const requestData = {
      name: data.name,
    }

    const project = await fetch(
      process.env.REACT_APP_TRON_URL + "/create/project",
      {
        method: "POST",
        body: JSON.stringify(requestData),
      }
    ).then((response) => response.json())

    // await axios.post(
    //   process.env.REACT_APP_TRON_URL + "/create/project",
    //   JSON.stringify(requestData),
    //   {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Content-Type": "application/vnd.api+json",
    //     },
    //   }
    // )

    // here's 2
    const document = await collection.insert({
      ...data,
      id: project.data.id,
      updatedAt: new Date().getTime(),
    })

    if (data.logo) {
      await document?.putAttachment({
        id: "logo.png",
        data: data.logo,
        type: "image/png",
      })
    }

    onClose()
  }

  return (
    <Box px={2} color="white">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyItems="space-around" spacing={6}>
          <Grid item xs={12}>
            <ImageInput
              inputType="Image"
              text="Upload a logo for your project"
              label="Logo"
              name="logo"
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              inputType="Text"
              label="Project Name"
              name="name"
              register={register}
            />
          </Grid>

          {/* <Grid item key={1} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter the Azure Subscription ID"
                name="subscription_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={2} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter my Managed Identity Id"
                name="managed_identity_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={3} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter my Managed Identity Secret"
                name="managed_identity_secret"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={4} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter the Tenant Id"
                name="tenant_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={6} xs={12}>
            <Box mb={2}>
              <DirectoryInput
                inputType="Directory"
                label="Application Directory"
                name="frontend_directory"
                type="object"
                control={control}
              />
            </Box>
          </Grid>

          <Grid item key={7} xs={12}>
            <Box mb={2}>
              <DirectoryInput
                inputType="Directory"
                label="Database Directory"
                name="server_directory"
                type="object"
                control={control}
              />
            </Box>
          </Grid> */}

          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default ProjectForm
