import Inputs from "./Inputs"

export const renderInputs = (inputs) => {
  const result = inputs.map((input, index) => {
    switch (input.type) {
      case Inputs.Checkbox:
      case Inputs.Image:
        return `<Grid item key={${index}} xs={12}>
          <Box mb={2}>
            <${input.type.concat("Input")} 
              ${formatInputProps(input)}
              control={control}
            />
          </Box>
        </Grid>
        `

      default:
        return `<Grid item key={${index}} xs={12}>
          <Box mb={2}>
            <${input.type.concat("Input")}
              ${formatInputProps(input)}
              register={register}
            />
          </Box>
        </Grid>
        `
    }
  })
  return result.join("\n")
}

const formatInputProps = (input) => {
  const result = Object.entries(input)
    .map((property) => {
      switch (property[0]) {
        case "options":
          return `${property[0]}={["${property[1].join('", "')}"]}` // this is to support dropdown options : object of an Array()
        default:
          return `${property[0]}="${property[1]}"`
      }
    })
    .join("\n")
  return result.toString()
}
