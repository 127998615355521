const IndexedDB = {
  name: "IndexedDB",
  template: (props) => template(props),
};

const template = (
  props
) => `import { addRxPlugin, createRxDatabase } from "rxdb";
import { getRxStoragePouch, addPouchPlugin } from 'rxdb/plugins/pouchdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import schemas from "./schemas"
import { RxDBReplicationGraphQLPlugin } from "rxdb/plugins/replication-graphql";
${
  props.isAttachmentsRequired
    ? 'import { RxDBAttachmentsPlugin } from "rxdb/plugins/attachments";'
    : ""
}

addPouchPlugin(require('pouchdb-adapter-idb'));
${props.isAttachmentsRequired ? "addRxPlugin(RxDBAttachmentsPlugin);" : ""}
addRxPlugin(RxDBReplicationGraphQLPlugin);
addRxPlugin(RxDBDevModePlugin);

const initialize = async () => {
  const db = await createRxDatabase({
    name: 'placeholder',
    storage: getRxStoragePouch('idb'),
    ignoreDuplicate: true
  });

  schemas.forEach(async schema => {
    await db.addCollections(schema)
  })

  return db;
};

export default initialize
`;

export default IndexedDB;
