import { CapitalizeFirstLetter } from "../../forms/FormHelpers"

const Root = {
  imports: `import Root from './router/Root'`,
  html: (indentations) =>
    `${[...Array(indentations).keys()].reduce((sum) => sum + " ", "")}`.concat(
      "<Root />"
    ),
  name: "Root",
  template: (routes) => component(routes),
}

const component = (
  routes
) => `import { Divider, Drawer, Link, Box } from '@mui/material'

const drawerWidth = "240px"

const Root = ({ children }) => (
  <>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
          // TODO: Make this based on application background color (primary or secondary) 
          boxShadow:
            "5px 0px 20px 0px rgba(0, 0, 0, 0.2), 10px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box p={2} pt={6}>
        <Box height={180}>
          <img src="./logo.png" alt="logo" width={210} />
        </Box>
      </Box>

      <Box p={2}>
        <Box p={2}>
          <Link color="#FAF8F5" href="/" variant="h6" underline="none">${CapitalizeFirstLetter(
            "home"
          )}</Link>
        </Box>
        ${routes
          .map(
            (route, index) => `<Box p={2} key={${index}}>
          <Link color="#FAF8F5" href="${
            route.name
          }" variant="h6" underline="none">${CapitalizeFirstLetter(
              route.name
            )}</Link>
        </Box>`
          )
          .join("")}
      </Box>
    </Drawer>
  
    <Box p={2} pl={34}>
      {children}
    </Box>
  </>
    
)

export default Root
`

export default Root
