import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useRxData } from "rxdb-hooks"
import { mainBarWidth } from "../router/Root"
import { SECONDARY_MAIN } from "../themes/theme"
import ProjectForm from "./ProjectForm"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"

const ProjectsNavigation = ({ setProject }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const { result: projects, isFetching } = useRxData("project", (collection) =>
    collection.find()
  )

  if (isFetching) {
    return <CircularProgress />
  }

  const ProjectTab = ({ project }) => {
    const [image, setImage] = useState()
    useEffect(() => {
      const fetchData = async () => {
        if (project) {
          const data = await project.getAttachment("logo.png")?.getData()
          setImage(data)
        }
      }

      fetchData().catch(console.error)
    }, [project])

    return (
      <Tooltip title={project.name} placement="right-end">
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => setProject(project)}
            sx={{
              height: "60px",
              ":hover": {
                cursor: "pointer",
                backgroundColor: "#EF2D7D30",
              },
            }}
          >
            <Grid item>
              {image && (
                <img
                  style={{ borderRadius: "25%" }}
                  src={URL.createObjectURL(image)}
                  alt="logo"
                  width="60%"
                  height="auto"
                />
              )}
            </Grid>

            {!image && <Typography variant="h4">{project.name[0]}</Typography>}
          </Grid>
          <Divider sx={{ borderColor: SECONDARY_MAIN }} />
        </>
      </Tooltip>
    )
  }

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        {projects.map((project, idx) => (
          <ProjectTab key={idx} project={project} />
        ))}
        <Box py={2}>
          <IconButton
            color="text"
            size="small"
            edge="start"
            aria-label="menu"
            onClick={() => setIsFormOpen(true)}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      <Zoom in={isFormOpen} style={{ transitionDelay: "200ms" }} unmountOnExit>
        <Card
          sx={{
            backgroundColor: SECONDARY_MAIN,
            borderRadius: "10px",
            height: "40vh",
            width: "55%",
            maxWidth: "400px",
            left: "35px",
            marginLeft: mainBarWidth,
            position: "fixed",
            zIndex: 1299,
          }}
          raised
        >
          <Box>
            <CardContent>
              <Grid container justifyContent="end" mb={2} spacing={-2}>
                <Grid item xs={1}>
                  <IconButton size="large" onClick={() => setIsFormOpen(false)}>
                    <CloseIcon fontSize="inherit" color="white" />
                  </IconButton>
                </Grid>
              </Grid>

              <ProjectForm onClose={() => setIsFormOpen(false)} />
            </CardContent>
          </Box>
        </Card>
      </Zoom>
    </>
  )
}

export default ProjectsNavigation
