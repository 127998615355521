const Inputs = {
  Checkbox: "Checkbox",
  Date: "Date",
  DateTime: "DateTime",
  Directory: "Directory",
  Dropdown: "Dropdown",
  Image: "Image",
  Images: "Images",
  PhoneNumber: "PhoneNumber",
  PostalCode: "PostalCode",
  Text: "Text",
  Time: "Time",
};

export default Inputs;
