import {
  Divider,
  Drawer,
  Link,
  Box,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material"
import { SECONDARY_MAIN } from "../themes/theme"
import ProjectsNavigation from "../project/ProjectNavigation"
import { useContext } from "react"
import { Navigate } from "react-router"
import { AuthContext } from "../App"
import VoiceCommander from "../voice_commander/VoiceCommander"
import { useNavigate } from "react-router"
import { CARDS_COLOR } from "../common/layout/theme"
import LogoutIcon from "@mui/icons-material/Logout"

export const mainBarWidth = "80px"

const Root = ({ children }) => {
  const { state, dispatch } = useContext(AuthContext)
  const navigate = useNavigate()

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />
  }

  const { avatar_url, login } = state.user

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    })
  }

  const handleSelectProject = async (rxdbProject) => {
    const project = await rxdbProject.collection
      .findOne({
        selector: {
          id: rxdbProject.id,
        },
      })
      .exec()

    project.logo = await project.getAttachment("logo.png").getData()

    dispatch({
      type: "SELECT_PROJECT",
      payload: { selectedProject: project },
    })

    navigate(`/project/${project.id}`)
  }

  return (
    <>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            marginY: "24px",
            marginLeft: "24px",
            width: mainBarWidth,
            height: "fit-content",
            boxSizing: "border-box",
            backgroundColor: CARDS_COLOR,
            overflowX: "hidden",
            borderRadius: "8px",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
        variant="permanent"
        anchor="left"
        color="primary"
      >
        <Box p={2.5} mt={1}>
          <Link color="secondary" href="/" variant="h6" underline="none">
            <img src="/logo512.png" alt="logo" width="100%" height="auto" />
          </Link>
        </Box>

        <Divider sx={{ borderColor: SECONDARY_MAIN }} />

        <ProjectsNavigation setProject={handleSelectProject} />
      </Drawer>

      <Box py={2} pl={10}>
        {children}
      </Box>

      <Box
        p={2}
        mb={1}
        ml={1}
        sx={{ position: "fixed", bottom: "0px", zIndex: 120 }}
      >
        <Card
          sx={{
            backgroundColor: CARDS_COLOR,
            borderRadius: "10px",
          }}
          raised
        >
          <Box
            sx={{
              justifyContent: "flex-end",
              width: "220px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px !important",
              }}
            >
              <IconButton color="text">
                <img
                  src={avatar_url}
                  alt="Avatar"
                  width={30}
                  style={{ borderRadius: "50%" }}
                />
              </IconButton>
              <Typography variant="subtitle1" color="white.main">
                {login}
              </Typography>
              <IconButton
                color="secondary"
                onClick={() => handleLogout()}
                variant="outlined"
              >
                <LogoutIcon />
              </IconButton>
            </CardContent>
          </Box>
        </Card>
      </Box>

      <VoiceCommander project={state.selectedProject} />

      <Box
        position="fixed"
        bottom={24}
        right={24}
        backgroundColor={SECONDARY_MAIN}
        sx={{
          cursor: "pointer",
          opacity: 0.6,
          "&:hover": {
            opacity: 1,
          },
          transition: "opacity 0.3s ease",
          textAlign: "center", // Centers the text
        }}
        onClick={() =>
          window.open("https://technologiescodebot.com/", "_blank")
        }
      >
        <img src="logo_text.png" alt="Logo" width="150px" />
      </Box>
    </>
  )
}

export default Root
