import BaseTheme from "./BaseTheme"

const ThemeProvider = {
  imports: `import { ThemeProvider } from "@mui/material"\nimport theme from "./common/layout/theme"`,
  html: (isBeginning, indentations) =>
    `${[...Array(indentations).keys()].reduce((sum) => sum + " ", "")}`.concat(
      isBeginning ? "<ThemeProvider theme={theme}>" : "</ThemeProvider>"
    ),
  name: "ThemeProvider",
  template: (colors) => component(colors),
}

const component = (colors) => BaseTheme(colors) // mui theme

export default ThemeProvider
