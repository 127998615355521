import { generateAll, generateGithubActions } from "./commands"

const dispatchAction = async (messageData, project, root_directory) => {
  const currentURL = window.location.href // Split the URL by '/' to get an array of parts
  const urlParts = currentURL.split("/")
  const projectId = urlParts[urlParts.length - 1] // Get the last part of the URL

  switch (messageData.action) {
    case "createProject":
      const logo = messageData.data.logo

      const document = await project.insert({
        ...messageData.data,
        id: getRandomIntString(1, 10000000000),
        updatedAt: new Date().getTime(),
      })

      try {
        // Convert base64 to Blob
        const byteCharacters = atob(logo)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: "image/jpeg" }) // Specify the correct MIME type

        // Create a File object
        const filename = "logo.png" // Replace 'yourFileName.jpg' with your desired file name and extension
        const options = { type: "image/png" } // Specify the correct MIME type
        const file = new File([blob], filename, options)

        await document?.putAttachment({
          id: "logo.png",
          data: file,
          type: "image/png",
        })
      } catch (error) {
        console.error("Error downloading file:", error)
      }

      break
    case "createWebApp":
      await generateAll(project, root_directory)
      if (messageData.data.isGithubActions)
        await generateGithubActions(root_directory)
      break
    default:
      console.log(
        `Action: ${messageData.action} not implemented in dispatchAction`
      )
      console.log("messageData: ", messageData)
      break
  }
}

function getRandomIntString(min, max) {
  // The Math.floor() function returns the largest integer less than or equal to a given number.
  // Math.random() returns a random floating-point number between 0 (inclusive) and 1 (exclusive).
  const randomInt = Math.floor(Math.random() * (max - min + 1)) + min

  return randomInt.toString()
}

export default dispatchAction
