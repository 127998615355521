
const DatabaseProvider = {
  imports: `import DatabaseProvider from './database/DatabaseProvider'`,
  html: (isBeginning, indentations) => `${[...Array(indentations).keys()].reduce(sum => sum + ' ', '')}`.concat(isBeginning ? '<DatabaseProvider>' : '</DatabaseProvider>'),
  name: 'DatabaseProvider',
  template: () => component()
}

const component = () => `import initialize from ".";
import { Provider } from "rxdb-hooks";
import { useEffect, useState } from "react";

const DatabaseProvider = (props) => {
  const [database, setDatabase] = useState()
  useEffect(() => {
    initialize().then(setDatabase);
  }, []);

  return (
    <Provider db={database}>
      { props.children }
    </Provider>
  )
}

export default DatabaseProvider
`

export default DatabaseProvider