import { addBreaklines } from "./FileSystemHelpers"

const GRANTED = "granted"
const READWRITE = "readwrite"

export async function verifyPermission(fileHandle) {
  const options = {
    mode: READWRITE,
  }
  let isPermit = false

  if ((await fileHandle.queryPermission(options)) === GRANTED) {
    isPermit = true
  }
  if ((await fileHandle.requestPermission(options)) === GRANTED) {
    isPermit = true
  }
  return isPermit
}

export async function getDirectoryHandle() {
  const options = {
    mode: READWRITE,
  }
  // let isPermit = false

  const directoryHandle = await openDirectoryPicker()

  if ((await directoryHandle.queryPermission(options)) === GRANTED) {
    // isPermit = true
  }
  if ((await directoryHandle.requestPermission(options)) === GRANTED) {
    // isPermit = true
  }

  return directoryHandle
}

export const openFilePicker = async () => {
  let [fileHandle] = await window.showOpenFilePicker()
  return fileHandle
}

export const openDirectoryPicker = async () => {
  let directoryHandle = await window.showDirectoryPicker()
  return directoryHandle
}

export const createFile = async (directory, fileName) =>
  directory.getFileHandle(fileName, { create: true })

export const writeFile = async (fileHandle, content) => {
  const writable = await fileHandle.createWritable()
  await writable.write({ data: content, type: "write" })
  await writable.close()
}

export const addFile = async (directory, file, properties) => {
  createFile(directory, file.name).then((fileHandle) =>
    writeFile(fileHandle, file.template(properties))
  )
}

const getLines = async (fileHandle) => {
  const file = await fileHandle.getFile()
  const contents = await file.text()
  const lines = contents.split("\n")
  return lines
}

export const addFunctionToFile = async (fileHandle, content) => {
  const lines = await getLines(fileHandle, content)

  if (!lines.includes(content)) lines.push(content)

  const linesWithBreakLine = addBreaklines(lines)
  const cleanedUpLines = linesWithBreakLine.toString().replace(/,/g, "") // dups // erase , in functions parameters .... -_-

  await writeFile(fileHandle, cleanedUpLines)
}

export const getReactFileHandle = async () => {
  const options = {
    types: [
      {
        description: "React File",
        accept: {
          "text/plain": [".jsx"],
        },
      },
    ],
  }
  const handle = await window.showSaveFilePicker(options)

  return handle
}
