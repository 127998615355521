const NodeJSServer = {
  template: () => template(),
}

const template = () => `
import express from 'express'
import cors from 'cors'
import dotenv from 'dotenv'
import schemas from './database/schemas.js'
import { PubSub } from 'graphql-subscriptions';
import { graphqlHTTP } from 'express-graphql';
import rootValue from './database/rootValue/index.js' 
import { db } from './database/database.js';
import GraphQLSchemaBuilder from './database/GraphQLSchemaBuilder.js';

dotenv.config()
const app = express()
app.use(cors())

const port = 3101

db()

export const pubsub = new PubSub();
app.use(
  "/graphql",
  graphqlHTTP({
    schema: GraphQLSchemaBuilder(schemas),
    rootValue: rootValue,
    graphiql: true,
  })
)

app.get('/', (req, res) => {
  res.send('Hello World!')
})

app.listen(port, () => {
  console.log('Example app listening on ...')
})
`

export default NodeJSServer
