import { CapitalizeFirstLetter } from "../../forms/FormHelpers"

const BaseTablePage = (page) => {
  const capitalizedName = CapitalizeFirstLetter(page.name)
  return `import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { Box } from "@mui/system"
import ${capitalizedName}Table from "./${capitalizedName}Table"
import ${capitalizedName}Form from "./${capitalizedName}Form"
import { useState } from "react";

const ${capitalizedName}Page = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ${capitalizedName}
          </Typography>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsFormOpen(true)}
          >
            <AddIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <Box py={4}>
        <${capitalizedName}Table />
      </Box>

      <Drawer
        anchor="right"
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      >
        <${capitalizedName}Form />
      </Drawer>
    </>
  )
}

export default ${capitalizedName}Page
`
}

export default BaseTablePage
