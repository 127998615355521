import {
  GithubDirectory,
  GithubWorkflowDirectory,
} from "../../file_system/Directories"
import { createFile, writeFile } from "../../file_system/FileSystemService"

const endOfLine = () => `\\`

export const addGithubActionWorkflow = async (projectDirectory, file) => {
  GithubDirectory(projectDirectory).then((directory) =>
    GithubWorkflowDirectory(directory).then((directory) =>
      createFile(
        directory,
        `BuildAndDeployContainerApps_PullRequest.yaml`
      ).then((fileHandle) => writeFile(fileHandle, file))
    )
  )
}

const renderSyncImageToDeploy = () =>
  "${{ env.AZURE_CONTAINER_REGISTRY }}.azurecr.io/${{ env.SYNC_CONTAINER_NAME }}:${{ github.sha }}"
const renderWebImageToDeploy = () =>
  "${{ env.AZURE_CONTAINER_REGISTRY }}.azurecr.io/${{ env.WEB_CONTAINER_NAME }}:${{ github.sha }}"
const renderAzureContainerRegistryVariable = () =>
  "${{ env.AZURE_CONTAINER_REGISTRY }}"

const renderAzureClientId = () => "${{ secrets.AZURE_CLIENT_ID }}"
const renderAzureClientSecret = () => "${{ secrets.AZURE_CLIENT_SECRET }}"
const renderSynchronizerPath = () => "${{ github.workspace }}/synchronizer"
const renderWebPath = () => "${{ github.workspace }}/web-application"
const renderAzureCredentials = () => "${{ secrets.AZURE_CREDENTIALS }}"
const renderSyncContainerAppName = () => "${{ env.SYNC_CONTAINER_NAME }}"
const renderWebContainerAppName = () => "${{ env.WEB_CONTAINER_NAME }}"
const renderRessourceGroup = () => "${{ env.RESOURCE_GROUP }}"
const renderLocation = () => "${{ env.LOCATION }}"
const renderContainerName = () => "${{ env.CONTAINER_NAME }}"

const renderWebApplicationProductionLine = () =>
  'echo "REACT_APP_SYNCHRONIZER_URL=${{ env.syncUrl }}" >> ./web/.env.production'

const renderGithubHeadRef = () => "$(echo ${{ github.head_ref }} | tr / -)"
const renderGithubHeadRef2 = () => "${{ github.head_ref }}"
const renderEnvVariableHeadRef = () => "${{ env.HEAD_REF }}"

const renderPullRequestResourceGroup = (name) =>
  `rg-${name}-pr-$(echo ${renderGithubHeadRef2()} | tr / -)`
const renderPullRequestResourceGroupEnvironment = (name) =>
  `${renderPullRequestResourceGroup(name)}-env`

const renderDatabaseUrl = () =>
  "${{ steps.deploy_database.outputs.databaseUrl }}"
const renderSyncUrl = () => "${{ steps.deploy_synchronizer.outputs.syncUrl }}"
const renderNeedsSyncUrl = () =>
  "${{ needs.update_synchronizer.outputs.syncUrl }}"
const renderNeedsDatabaseUrl = () =>
  "${{ needs.update_synchronizer.outputs.databaseUrl }}"

const renderPullRequestResourceGroupName = () =>
  "${{ env.PULL_REQUEST_RESOURCE_GROUP }}"
const renderPullRequestResourceGroupEnvironmentVariable = () =>
  "${{ env.PULL_REQUEST_CONTAINER_APPS_ENV_NAME }}"

const renderSynchronizerEnvironmentVariables = () =>
  "POSTGRES_HOST=${{ steps.deploy_database.outputs.databaseUrl }} POSTGRES_USER=postgres POSTGRES_PASSWORD=postgres POSTGRES_DB=database"

const renderOutputStepWebAppUrl = () =>
  "${{ steps.deploy_web_application.outputs.webAppUrl }}"

const renderSyncFqdnVariable = () => "${synchronizerfqdn}"
const renderDatabaseFqdnVariable = () => "${databasefqdn}"
const renderWebAppFqdn = () => "${webappfqdn}"

export const GithubActionWorkflowPullRequestBranchTemplate = {
  name: "GithubActionWorkflowPullRequestBranchTemplate",
  template: (
    name,
    environment,
    azureContainerRegistry,
    location,
    port,
    cpu_cores
  ) => `name: Build and Deploy ${name} to Container Apps

on:
  pull_request:

env:
  AZURE_CONTAINER_REGISTRY: ${azureContainerRegistry}
  WEB_CONTAINER_NAME: "web"
  SYNC_CONTAINER_NAME: "sync"
  RESOURCE_GROUP: "rg-${name}-${environment}"
  LOCATION: ${location}
  HEAD_REF: "${renderGithubHeadRef()}"
  PULL_REQUEST_RESOURCE_GROUP: "${renderPullRequestResourceGroup(name)}"
  PULL_REQUEST_CONTAINER_APPS_ENV_NAME: "${renderPullRequestResourceGroupEnvironment(
    name
  )}"

jobs:
  update_synchronizer:
    name: Update Synchronizer
    environment: production
    permissions:
      actions: read
      contents: read
      id-token: write
    runs-on: ubuntu-latest
    outputs:
      databaseUrl: ${renderDatabaseUrl()}
      syncUrl: ${renderSyncUrl()}
    steps:
      # Checks out the repository this file is in
      - uses: actions/checkout@v3

      # Logs in with your Azure credentials
      - name: Azure login
        uses: azure/login@v1.4.6
        with:
          creds: ${renderAzureCredentials()}

      - name: Create Resource Group
        run: |
          az group create ${endOfLine()}
            --name ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --location ${renderLocation()}

      - name: Deploy Postgres to Azure Container Apps
        id: deploy_database
        env:
          CONTAINER_NAME: db-${renderGithubHeadRef2()}
        run: |
          az config set extension.use_dynamic_install=yes_without_prompt
          az containerapp env create ${endOfLine()}
            --name  ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --resource-group  ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --location ${renderLocation()}
          databasefqdn=$(az containerapp create ${endOfLine()}
            --name ${renderContainerName()} ${endOfLine()}
            --resource-group ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --environment ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --env-vars POSTGRES_PASSWORD=postgres POSTGRES_USER=postgres POSTGRES_DB=database ${endOfLine()}
            --image postgres ${endOfLine()}
            --ingress external ${endOfLine()}
            --target-port 5432 ${endOfLine()}
            --query properties.configuration.ingress.fqdn ${endOfLine()}
            | jq -r '.')
          echo "databaseUrl=https://${renderDatabaseFqdnVariable()}" >> $GITHUB_OUTPUT

      - name: Build and push Synchronizer image to ACR
        id: build_sync
        run: |
          az acr build ${endOfLine()}
            --image ${renderSyncImageToDeploy()} ${endOfLine()}
            --registry ${renderAzureContainerRegistryVariable()} ${endOfLine()}
            -g ${renderRessourceGroup()} ./synchronizer

      - name: Deploy Synchronizer to Azure Container Apps
        id: deploy_synchronizer
        run: |
          az config set extension.use_dynamic_install=yes_without_prompt
          az containerapp env create ${endOfLine()}
            --name ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --resource-group ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --location ${renderLocation()}
          synchronizerfqdn=$(az containerapp create ${endOfLine()}
            --name ${renderSyncContainerAppName()} ${endOfLine()}
            --resource-group ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --environment ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --env-vars ${renderSynchronizerEnvironmentVariables()} ${endOfLine()}
            --image ${renderSyncImageToDeploy()} ${endOfLine()}
            --ingress external ${endOfLine()}
            --target-port 3100 ${endOfLine()}
            --registry-server ${renderAzureContainerRegistryVariable()}.azurecr.io ${endOfLine()}
            --registry-username ${renderAzureClientId()} ${endOfLine()}
            --registry-password ${renderAzureClientSecret()} ${endOfLine()}
            --query properties.configuration.ingress.fqdn ${endOfLine()}
            | jq -r '.')
          echo "syncUrl=https://${renderSyncFqdnVariable()}" >> $GITHUB_OUTPUT

  update_web_application:
    environment: production
    needs: [update_synchronizer]
    permissions:
      actions: read
      contents: read
      id-token: write
    runs-on: ubuntu-latest
    env:
      databaseUrl: ${renderNeedsDatabaseUrl()}
      syncUrl: ${renderNeedsSyncUrl()}
    outputs:
      webAppUrl: ${renderOutputStepWebAppUrl()}
    steps:
      # Checks out the repository this file is in
      - uses: actions/checkout@v3

      # Logs in with your Azure credentials
      - name: Azure login
        uses: azure/login@v1.4.6
        with:
          creds: ${renderAzureCredentials()}

      - name: Create .env.production file
        run: |
          ${renderWebApplicationProductionLine()}

      - name: Build and push Web App image to ACR
        id: build_web_application
        run: |
          az acr build ${endOfLine()}
            --image ${renderWebImageToDeploy()} ${endOfLine()}
            --registry ${renderAzureContainerRegistryVariable()} ${endOfLine()}
            --file ./web/Dockerfile.prod ${endOfLine()}
            -g ${renderRessourceGroup()} ./web

      - name: Deploy Web Application to Azure Container Apps
        id: deploy_web_application
        run: |
          az config set extension.use_dynamic_install=yes_without_prompt
          az containerapp env create ${endOfLine()}
            --name ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --resource-group ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --location ${renderLocation()}
          webappfqdn=$(az containerapp create ${endOfLine()}
            --name ${renderWebContainerAppName()} ${endOfLine()}
            --resource-group ${renderPullRequestResourceGroupName()} ${endOfLine()}
            --environment ${renderPullRequestResourceGroupEnvironmentVariable()} ${endOfLine()}
            --image ${renderWebImageToDeploy()} ${endOfLine()}
            --ingress external ${endOfLine()}
            --cpu ${cpu_cores} ${endOfLine()}
            --memory ${cpu_cores * 2}.0Gi ${endOfLine()}
            --target-port ${port} ${endOfLine()}
            --registry-server ${renderAzureContainerRegistryVariable()}.azurecr.io ${endOfLine()}
            --registry-username ${renderAzureClientId()} ${endOfLine()}
            --registry-password ${renderAzureClientSecret()} ${endOfLine()}
            --query properties.configuration.ingress.fqdn ${endOfLine()}
            | jq -r '.')
          echo "webAppUrl=https://${renderWebAppFqdn()}" >> $GITHUB_OUTPUT
`,
}
