const DateTimeInput = {
  name: "DateTimeInput",
  template: () => `import { TextField } from "@material-ui/core"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react"

const DateTimeInput = ({ defaultValue, label, name, register }) => {
  const [value, setValue] = useState(dayjs())
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        name={name}
        value={value}
        type="datetime-local"
        onChange={setValue}
        {...register(name, { required: true })}
        renderInput={(params) => <TextField {...params} fullWidth variant="outlined"/>}
      />   
    </LocalizationProvider>
  )
}

export default DateTimeInput
`,
}

export default DateTimeInput
