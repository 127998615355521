import { Drawer, IconButton } from "@mui/material"
import { Box } from "@mui/system"
import React, { useCallback, useEffect, useState } from "react"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { CARDS_COLOR } from "../common/layout/theme"
import dispatchAction from "./TronActionsController.js"
import Webchat from "./Webchat"
import TronWebsocket from "./TronWebsocket"
import { useRxCollection } from "rxdb-hooks"

const VoiceCommander = ({ project }) => {
  const projectCollection = useRxCollection("project")

  const [isOpen, setIsOpen] = useState(false)

  // const isProjectMounted = !!project

  // const handleStopRecording = (data) => transformAudioToText(data)
  // const [searchInputText, setSearchInputText] = useState()
  // useEffect(() => websocket.init(setSearchInputText), [])

  const handleAction = useCallback(
    (data) => {
      dispatchAction(data, projectCollection, project)
    },
    [projectCollection, project]
  )

  useEffect(() => {
    if (!TronWebsocket.isNotConnected() && project) {
      if (project) {
        TronWebsocket.close()
        TronWebsocket.init(handleAction)
      }
    } else if (projectCollection && TronWebsocket.isNotConnected()) {
      TronWebsocket.init(handleAction)
    }
  }, [handleAction, projectCollection, project])

  return (
    <>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            zIndex: 100,
            marginY: "24px",
            marginLeft: "24px",
            width: isOpen ? "74%" : "60px",
            maxWidth: isOpen ? "700px" : "60px",
            height: "85vh",
            boxSizing: "border-box",
            backgroundColor: CARDS_COLOR,
            overflow: "hidden",
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
        anchor="right"
        color="primary"
        variant="permanent"
        open={isOpen}
      >
        <Box mt={1} px={2} py={1}>
          <IconButton
            size="small"
            edge="start"
            color="white"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isOpen && <ArrowBackIosNewIcon />}
            {isOpen && <ArrowForwardIosIcon />}
          </IconButton>
        </Box>

        <>
          {isOpen && (
            <>
              <Webchat />
              {/* <AudioRecorder onStopRecording={handleStopRecording} />
              <TextField
                InputProps={{ style: { color: "white" } }}
                value={searchInputText}
                onChange={(e) => setSearchInputText(e.target.value)}
              ></TextField> */}
            </>
          )}
        </>
      </Drawer>
    </>
  )
}
export default VoiceCommander
