import { TextField } from "@material-ui/core"
import React, { useEffect } from "react"

const TextInput = ({
  endAdornment,
  errors = false,
  errorText = "",
  label,
  name,
  placeholder,
  register = () => {},
  type,
  defaultValue = "",
  resetTriggered,
}) => {
  const [value, setValue] = React.useState(defaultValue)

  const handleOnChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (resetTriggered) setValue("")
  }, [defaultValue, resetTriggered])

  const inputStyle = {
    // MUI theming should be overrided in the MUI Theme Provider
    color: "white",
  }

  return (
    <>
      <TextField
        autoComplete="off"
        {...register(name, { required: true })}
        InputLabelProps={{
          style: { color: errors ? "red" : "white" },
        }}
        InputProps={{
          style: inputStyle,
          endAdornment: endAdornment,
        }}
        error={errors}
        fullWidth
        helperText={errorText}
        label={label}
        name={name}
        onChange={handleOnChange}
        placeholder={placeholder}
        required
        type={type}
        value={value}
        variant="outlined"
      />
    </>
  )
}

export default TextInput
