const ImageInput = {
  name: "ImageInput",
  template: () => `import { Grid, IconButton } from "@mui/material";
  import { useEffect, useState } from "react";
  import AddIcon from "@mui/icons-material/Add";
  import { Controller } from "react-hook-form";
  
  const ImageInput = ({ control, document, name, rules }) => {
    const [image, setImage] = useState();
  
    useEffect(() => {
      const fetchData = async () => {
        if (document) {
          // null checks are for dummies
          const data = await document.getAttachment("icon.jpg").getData();
          setImage(data);
        }
      };
  
      fetchData().catch(console.error);
    }, [document]);
  
    const handleOnChange = (e, field) => {
      setImage(e.target.files[0]);
      field.onChange(e.target.files[0]);
    };
  
    return (
      <Grid
        container
        sx={{
          maxWidth: 150,
          height: 150,
          backgroundColor: "#D3D3D3",
        }}
        alignItems="center"
        justifyContent="center"
      >
        {image && (
          <img
            src={URL.createObjectURL(image)}
            alt="toto"
            height="80%"
            width="80%"
          />
        )}
        {!image && (
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
            }}
          >
            {control && (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <label htmlFor="raised-button-file">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={(e) => handleOnChange(e, field)}
                    />
                    <IconButton variant="raised" component="span">
                      <AddIcon />
                    </IconButton>
                  </label>
                )}
                rules={rules}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  };
  
  export default ImageInput;    
`,
};

export default ImageInput;
