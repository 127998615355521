import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useRxCollection } from "rxdb-hooks"
import TextInput from "../common/components/forms/inputs/TextInput"
import { PRIMARY_MAIN } from "../themes/theme"
import { CARDS_COLOR, CARDS_LIGHT, TEXT_MAIN } from "../common/layout/theme"
import AddIcon from "@mui/icons-material/Add"
import { useContext, useEffect, useState } from "react"
import FormForm from "./FormForm"
import { createUniqueId } from "../common/utils/utilities"
import { useParams } from "react-router"
import GoogleIcon from "@mui/icons-material/Google"
import DeploymentForm from "../deployment/DeploymentForm"
import DropdownInput from "../common/components/forms/inputs/DropdownInput"
import { AuthContext } from "../App"
import axios from "axios"

import { MuiColorInput } from "mui-color-input"
import styled from "styled-components"
import GithubRepositories from "./GitubRepositories"

const MuiColorInputStyled = styled(MuiColorInput)`
  & .MuiInputBase-input {
    color: ${TEXT_MAIN};
  }
`

const WebApplicationForm = ({ onComplete }) => {
  let { id } = useParams()
  const { state } = useContext(AuthContext)
  const collection = useRxCollection("web_application")
  const [isFormFormOpen, setIsFormFormOpen] = useState(false)
  const [isAuthentificationFormOpen, setIsAuthentificationFormOpen] =
    useState(false)
  const [forms, setForms] = useState([])
  const [deployments, setDeployments] = useState([])
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
  const [resetTriggered, setResetTriggered] = useState(false)
  const [selectedRecipy, setSelectedRecipy] = useState()
  const [isDeploymentFormOpen, setIsDeploymentFormOpen] = useState(false)
  const [primaryColor, setPrimaryColor] = useState("#000")
  const [secondaryColor, setSecondaryColor] = useState("#e91d1d")

  const [selectedRepository, setSelectedRepository] = useState()

  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async (data) => {
    const requestData = {
      deployment: deployments[0],
      name: data.name,
      forms: forms,
      colors: { primaryColor, secondaryColor },
      project_id: id,
    }

    const document = await collection.insert({
      ...requestData,
      id: createUniqueId(),
      updatedAt: new Date().getTime(),
    })

    onComplete()
    setIsSubmitSuccessful(true)
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setResetTriggered(true)
      setForms([])
    }
  }, [isSubmitSuccessful, reset])

  const handleClose = () => {
    setIsFormFormOpen(false)
  }

  const renderAddButton = (onClick, size) => (
    <Card sx={{ width: size, height: size, backgroundColor: CARDS_COLOR }}>
      <CardActions
        sx={{
          padding: 0,
          minWidth: size,
          height: size,
          backgroundColor: CARDS_COLOR,
          display: "flex",
          justifyContent: "center", // Center horizontally
        }}
      >
        <IconButton
          sx={{
            textAlign: "center",
            backgroundColor: CARDS_COLOR,
          }}
          color="text"
          size="small"
          edge="start"
          aria-label="menu"
          onClick={() => onClick(true)}
        >
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  )

  const renderFormCard = (form, index) => (
    <Grid item xs={3} key={index}>
      <Card sx={{ width: 75, height: 75, backgroundColor: CARDS_COLOR }}>
        <CardActions
          sx={{
            padding: 0,
            minWidth: 75,
            height: 75,
            backgroundColor: CARDS_COLOR,
            display: "flex",
            justifyContent: "center", // Center horizontally
          }}
        >
          <CardActionArea
            sx={{
              textAlign: "center",
              backgroundColor: CARDS_COLOR,
            }}
            //</CardActions>={() => onClick(true)}
          >
            <Typography variant="h4" color="text.main">
              {form.name.substring(0, 1)}
            </Typography>
          </CardActionArea>
        </CardActions>
      </Card>
    </Grid>
  )
  const renderDeploymentCard = (deployment, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Card sx={{ backgroundColor: CARDS_COLOR }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.main" gutterBottom>
              Environment: {deployment.environment_name}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.main" gutterBottom>
              Branch:
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const handleAddForms = (newForm) => {
    setForms((prevForms) => [...prevForms, newForm])
  }

  const handleAddDeployments = (newDeployment) => {
    setDeployments((prevDeployments) => [...prevDeployments, newDeployment])
  }

  const authentifications = [
    { name: "Github", logo: "" },
    { name: "Google", logo: "" },
  ]

  const offlineFirstTechnologyStack = [
    { name: "React", url: "https://react.dev/" },
    { name: "RxDB", url: "https://rxdb.info/" },
    { name: "PostgreSQL", url: "https://www.postgresql.org/" },
    { name: "React Hook Form", url: "https://react-hook-form.com/" },
    { name: "Material UI", url: "https://mui.com/material-ui/" },
    { name: "GraphQL", url: "https://graphql.org/" },
  ]

  const handleSetRecipy = (recipy) => {
    if (selectedRecipy == recipy) setSelectedRecipy()
    else setSelectedRecipy(recipy)
  }

  const isRecipySelected = (recipy) => {
    return recipy == selectedRecipy
  }

  return (
    <Box px={2}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={1} justifyItems="space-between">
          <Grid item key={0} xs={12} py={4}>
            <Typography color={PRIMARY_MAIN} variant="h6">
              Créer une application web
            </Typography>
          </Grid>
          <Grid item key={1} xs={12} mb={2}>
            <Box>
              <TextInput
                inputType="Text"
                label="Nom"
                name="name"
                register={register}
                resetTriggered={resetTriggered}
              />
            </Box>
          </Grid>
          {/* <Grid item key={2} xs={12} mb={8}>
            <Box>
              <TextInput
                inputType="Text"
                label="Domain Name"
                name="domain_name"
                register={register}
              />
            </Box>
          </Grid> */}
          {/*
          <Grid item xs={12} key={3} mb={1}>
            <Box>
              <GithubRepositories
                register={register}
                onChange={(value) => setSelectedRepository(value)}
              />
            </Box>
          </Grid>
        
          <Grid item xs={12} mb={4}>
            <Box>
              <TextInput
                inputType="Text"
                label="Root Folder"
                name="root_folder"
                register={register}
              />
            </Box>
          </Grid>
          */}

          <Grid item key={4} xs={12} mb={4}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Recettes
            </Typography>

            <Grid container>
              <Card
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: 150,
                  backgroundColor: isRecipySelected("REACT_OFFLINE_FIRST")
                    ? CARDS_COLOR
                    : CARDS_LIGHT,
                }}
                onClick={() => handleSetRecipy("REACT_OFFLINE_FIRST")}
              >
                <Box py={1.5} px={2}>
                  <Box mb={2}>
                    <Typography color="primary">
                      PWA de React à PostgreSQL
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    useFlexGap
                    spacing={1}
                    sx={{ overflowX: "-moz-initial" }}
                  >
                    {offlineFirstTechnologyStack.map((technology, index) => (
                      <Chip
                        size="small"
                        key={index}
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        label={technology.name}
                        variant="outlined"
                        onClick={() => window.open(technology.url)}
                      />
                    ))}
                  </Stack>
                </Box>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Couleur primaire
            </Typography>
            <MuiColorInputStyled
              format="hex"
              value={primaryColor}
              onChange={(value) => setPrimaryColor(value)}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Couleur secondaire
            </Typography>
            <MuiColorInputStyled
              format="hex"
              value={secondaryColor}
              onChange={(value) => setSecondaryColor(value)}
            />
          </Grid>

          <Grid item key={5} xs={12} mb={2}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Authentification
            </Typography>
            <Modal open={isAuthentificationFormOpen}>
              <>
                <FormForm
                  setIsOpen={setIsAuthentificationFormOpen}
                  onComplete={handleAddForms}
                />
              </>
            </Modal>

            <Grid container columnSpacing={2}>
              <Grid item>
                <Card
                  sx={{ width: 75, height: 75, backgroundColor: CARDS_COLOR }}
                >
                  <CardActions
                    sx={{
                      padding: 0,
                      minWidth: 75,
                      height: 75,
                      backgroundColor: CARDS_COLOR,
                      display: "flex",
                      justifyContent: "center", // Center horizontally
                    }}
                  >
                    <CardActionArea
                      sx={{
                        textAlign: "center",
                        backgroundColor: CARDS_COLOR,
                      }}
                      //</CardActions>={() => onClick(true)}
                    >
                      <img
                        src="./../images/github-mark.png"
                        alt="github"
                        width="50px"
                      />
                    </CardActionArea>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item>
                <Card
                  sx={{
                    width: 75,
                    height: 75,
                    backgroundColor: CARDS_COLOR,
                    alignItems: "center",
                  }}
                >
                  <CardActions
                    sx={{
                      padding: 0,
                      minWidth: 75,
                      height: 75,
                      backgroundColor: CARDS_COLOR,
                      display: "flex",
                      justifyContent: "center", // Center horizontally
                    }}
                  >
                    <CardActionArea
                      sx={{
                        textAlign: "center",
                        backgroundColor: CARDS_COLOR,
                      }}
                      //</CardActions>={() => onClick(true)}
                    >
                      <GoogleIcon color="white" fontSize="large" />
                    </CardActionArea>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item key={5} xs={12} mb={2}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Structures de données
            </Typography>
            <Modal open={isFormFormOpen} sx={{ overflowY: "scroll" }}>
              <>
                <FormForm
                  setIsOpen={setIsFormFormOpen}
                  onComplete={handleAddForms}
                />
              </>
            </Modal>

            <Grid container rowGap={1}>
              {forms?.map((form, index) => renderFormCard(form, index))}
              <Grid item>{renderAddButton(setIsFormFormOpen, 75)}</Grid>
            </Grid>
          </Grid>

          <Grid item key={5} xs={12} mb={2}>
            <Typography color={PRIMARY_MAIN} variant="h6" mb={2}>
              Deployments
            </Typography>

            <Grid container rowGap={1}>
              {deployments?.map((deployment, index) =>
                renderDeploymentCard(deployment, index)
              )}
              {renderAddButton(setIsDeploymentFormOpen, 75)}
            </Grid>

            <Drawer
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  maxWidth: "500px",
                  boxSizing: "border-box",
                  backgroundColor: CARDS_COLOR,
                },
              }}
              anchor="right"
              color="primary"
              open={isDeploymentFormOpen}
            >
              <DeploymentForm
                closeForm={() => setIsDeploymentFormOpen(false)}
                onComplete={handleAddDeployments}
                repository={selectedRepository}
                register={register}
              />
            </Drawer>
          </Grid>

          <Grid item key={3} xs={12}>
            <Button
              sx={{ width: "100%" }}
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default WebApplicationForm
