import {
  generateAll,
  generateGithubActionsForPullRequestEnvironment,
} from "../voice_commander/commands";
import { getDirectoryHandle } from "../file_system/FileSystemService";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CARDS_COLOR } from "../common/layout/theme";
import { useState } from "react";
import { PINK, PRIMARY_MAIN, SECONDARY_MAIN } from "../themes/theme";
import AddIcon from "@mui/icons-material/Add";

const WebApplicationCard = ({ webApplication, project }) => {
  const [isWebApplicationDetailsOpen, setIsWebApplicationDetailsOpen] =
    useState(false);
  const onWebApplicationCardClick = (webApplication) => {
    setIsWebApplicationDetailsOpen(true);
  };

  const onWriteCodeToGithub = async (webApplication, project) => {
    console.log("write code to github");
    const root_directory = await getDirectoryHandle();

    generateGithubActionsForPullRequestEnvironment(
      project,
      webApplication,
      root_directory
    );
    // await generateAll(project, root_directory)
  };

  const onWebApplicationApply = async (webApplication, project) => {
    const root_directory = await getDirectoryHandle();

    await generateAll(project, webApplication, root_directory);

    generateGithubActionsForPullRequestEnvironment(
      project,
      webApplication,
      root_directory
    );
  };

  const recipeTechnologies = [
    { name: "React", url: "https://react.dev/" },
    { name: "React Hook Form", url: "https://react-hook-form.com/" },
    { name: "RxDB", url: "https://rxdb.info/" },
    { name: "GraphQL", url: "https://graphql.org/" },
    { name: "PostgreSQL", url: "https://www.postgresql.org/" },
  ];

  const authorizations = [{ name: "Github" }, { name: "Google" }];

  const deployments = [
    { technology: "Github Actions" },
    { technology: "Terraform Cloud" },
  ];

  const renderAddButton = (onClick, size) => (
    <Card
      sx={{
        width: size,
        height: size,
        backgroundColor: CARDS_COLOR,
      }}
    >
      <CardActions
        sx={{
          padding: 0,
          minWidth: size,
          height: size,
          backgroundColor: CARDS_COLOR,
          display: "flex",
          justifyContent: "center", // Center horizontally
        }}
      >
        <IconButton
          sx={{
            textAlign: "center",
            backgroundColor: CARDS_COLOR,
          }}
          color="text"
          size="small"
          edge="start"
          aria-label="menu"
          onClick={() => onClick(true)}
        >
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  );

  return (
    <>
      <Card
        sx={{
          width: "80%",
          height: "80%",
          maxWidth: "188px",
          backgroundColor: CARDS_COLOR,
          cursor: "pointer",
        }}
        onClick={() => onWebApplicationCardClick(webApplication)} // not sure here
      >
        <CardContent>
          <Typography color="text.main">{webApplication.name}</Typography>
        </CardContent>
      </Card>

      <Drawer
        sx={{
          flexShrink: 0,
          zIndex: 100000,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            backgroundColor: CARDS_COLOR,
          },
        }}
        color="text.main"
        anchor="bottom"
        open={isWebApplicationDetailsOpen}
        onClose={() => setIsWebApplicationDetailsOpen(false)}
      >
        <Box p={4}>
          <Grid container mb={4} alignContent="center">
            <Grid item xs={12} mb={2} key={1}>
              <Typography variant="h3" color="text.main">
                {webApplication.name}
              </Typography>
            </Grid>
            <Grid item xs={12} key={2}>
              <Stack direction="row" spacing={1}>
                {recipeTechnologies.map((technology, index) => (
                  <Chip
                    key={index}
                    size="small"
                    color="secondary"
                    sx={{ cursor: "pointer" }}
                    label={technology.name}
                    variant="outlined"
                    onClick={() => window.open(technology.url)}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={2} key={3}>
            <Typography color="text.main" variant="h6">
              Authentification
            </Typography>
          </Grid>
          {/*webApplication.authorization.map(renderFormCard)*/}
          <Grid item xs={12} mb={4} key={4}>
            <Stack direction="row" spacing={1}>
              {authorizations.map((authorization, index) => (
                <Chip
                  key={index}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  label={authorization.name}
                  variant="outlined"
                  onClick={() => console.log("Implement Me")}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} mb={2} key={5}>
            <Typography color="text.main" variant="h6">
              Structure de données
            </Typography>
          </Grid>

          <Grid item xs={12} mb={4} key={7}>
            <Stack direction="row" spacing={1}>
              {webApplication.forms.map((form, index) => (
                <Chip
                  key={index}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  label={form.name}
                  variant="outlined"
                  onClick={() => console.log("Implement Me")}
                />
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} mb={2} key={6}>
            <Typography color="text.main" variant="h6">
              Déploiements
            </Typography>
          </Grid>
          {/*webApplication.authorization.map(renderFormCard)*/}
          <Grid item xs={12} mb={4} key={7}>
            <Stack direction="row" spacing={1}>
              {deployments.map((deployment, index) => (
                <Chip
                  key={index}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  label={deployment.technology}
                  variant="outlined"
                  onClick={() => console.log("Implement Me")}
                />
              ))}
            </Stack>
          </Grid>
        </Box>

        <Box
          sx={{
            borderTop: "1px solid green",
            boxShadow:
              "0px -8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
            boxSizing: "border-box",
          }}
        >
          <Grid container>
            {/* <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                size="large"
                color="success"
                onClick={() => onWriteCodeToGithub(webApplication, project)}
              >
                Write Code to Github
              </Button>
            </Grid> */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                size="large"
                color="success"
                onClick={() => onWebApplicationApply(webApplication, project)}
              >
                Write Code Locally
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default WebApplicationCard;
