import { createTheme } from "@mui/material/styles"

export const PINK = "#EF2D7D"
export const PRIMARY_MAIN = "#FAF8F5"
export const SECONDARY_MAIN = "#212121"

export const appTheme = createTheme({
  typography: {
    fontFamily: [
      "Orbitron",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontFeatureSettings: "smcp",
  },
  palette: {
    primary: {
      main: PINK,
    },
    secondary: {
      main: PINK,
    },
  },
})
