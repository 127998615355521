import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  Card,
  CardActions,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useRxCollection } from "rxdb-hooks"
import TextInput from "../common/components/forms/inputs/TextInput"
import CloseIcon from "@mui/icons-material/Close"
import { useParams } from "react-router"
import { createUniqueId } from "../common/utils/utilities"
import DropdownInput from "../common/components/forms/inputs/DropdownInput"

const AZURE_REGIONS = [
  "canadaeast",
  "canadacentral",
  "eastus",
  "eastus2",
  "westus",
  "westus2",
  "centralus",
  "northcentralus",
  "southcentralus",
  "westcentralus",
  "brazilsouth",
  "uksouth",
  "ukwest",
  "francecentral",
  "francesouth",
  "germanywestcentral",
  "norwayeast",
  "switzerlandnorth",
  "switzerlandwest",
  "netherlandswest",
  "austriaeast",
  "australiaeast",
  "australiasoutheast",
  "centralindia",
  "southindia",
  "westindia",
  "japaneast",
  "japanwest",
  "koreacentral",
  "koreasouth",
  "southeastasia",
  "eastasia",
  "taiwancentral",
  "uaenorth",
  "uaecentral",
  "southafricawest",
  "southafricanorth",
]

const AzureContainerAppForm = ({ onComplete, register, setIsOpen }) => {
  let { id } = useParams()

  // const { register, getValues } = useForm() // obsolete : now using parent register

  const handleOnSubmit = async () => {
    // const data = getValues()

    onComplete()
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Box px={2} color="white">
      <form>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} py={1.6}>
            <Typography color="white.main" variant="h6">
              Configure an Azure Container App
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <IconButton size="large" onClick={() => setIsOpen(false)}>
              <CloseIcon fontSize="inherit" color="white" />
            </IconButton>
          </Grid>
        </Grid>

        <Box py={4}>
          <Grid item key={5} xs={12}>
            <Box mb={2}>
              <DropdownInput
                label="Location"
                name="location"
                options={AZURE_REGIONS}
                defaultValue="canadaeast"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={6} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Port"
                name="port"
                defaultValue="80" // fix port number, not working
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={7} xs={12}>
            <Box mb={2}>
              <DropdownInput
                label="CPU Cores"
                name="cpu_cores"
                options={[0.5, 1, 2, 4]}
                defaultValue={1}
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={9} xs={12}>
            <Button
              sx={{ width: "100%" }}
              color="secondary"
              size="large"
              // type="submit"
              onClick={handleOnSubmit}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default AzureContainerAppForm
