const TimeInput = {
  name: 'TimeInput',
  template: () => `import { TextField } from "@material-ui/core"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useState } from "react"

const TimeInput = ({ label, name, register }) => {
  const [value, setValue] = useState(dayjs())

  // move localizationProvider as context / useContext
  return ( 
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        {...register(name, { required: true })}
        renderInput={(params) => <TextField {...params} fullWidth variant="outlined"/>}
        onChange={e => setValue(e)}
        label={label}
        name={name}
        type="time"
        value={value}
      />
    </LocalizationProvider>
  )
}

export default TimeInput
`
}

export default TimeInput