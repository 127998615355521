import { blue } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { PINK } from "../../themes/theme"

export const SECONDARY_MAIN = "#212121"
export const CARDS_COLOR = "rgb(42, 42, 42)"
export const SECONDARY_MAIN_DARK = "#1e1e1e"
export const SUCCESS = "#4caf50"
export const TEXT_MAIN = "#FAF8F580"
export const TEXT_LIGHT = "#F2E0E1"
export const TEXT_MAIN_DISABLED = "#333333"
export const CARDS_LIGHT = "rgb(30, 30, 30)"

export const appTheme = createTheme({
  typography: {
    fontFamily: ["Sanchez", "serif"].join(","),
    fontFeatureSettings: "smcp",
  },
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: PINK,
    },
    text: {
      main: TEXT_MAIN,
      light: TEXT_LIGHT,
    },
    text_disabled: {
      main: TEXT_MAIN_DISABLED,
    },
    success: {
      main: SUCCESS,
    },
    card: {
      main: "rgb(42, 42, 42)",
      light: CARDS_LIGHT,
    },
    white: {
      main: "rgb(217, 217, 217)",
    },
  },
})
