import Inputs from "./inputs/Inputs"

export const LoginFormData = (errors) => ({
  name: "login",
  inputs: [
    {
      errors: errors.email,
      inputType: Inputs.Text,
      label: "Email",
      name: "email",
    },
    {
      errors: errors.password,
      inputType: Inputs.Text,
      label: "Password",
      name: "password",
    },
  ],
})

export const SignupFormData = (errors) => ({
  name: "signup",
  inputs: [...LoginFormData(errors), ...ProfileFormData(errors)],
})

const ProfileFormData = {
  name: "profile",
  inputs: [
    {
      inputType: Inputs.Text,
      label: "First Name",
      name: "firstName",
    },
    {
      inputType: Inputs.Text,
      label: "Last Name",
      name: "lastName",
    },
    {
      inputType: Inputs.PhoneNumber,
      label: "Phone Number",
      name: "phoneNumber",
    },
    {
      inputType: Inputs.PostalCode,
      label: "Postal Code",
      name: "postalCode",
    },
    {
      defaultValue: "Apple",
      inputType: Inputs.Dropdown,
      label: "Favorite Pie",
      name: "favoritePie",
      options: ["Apple", "Carrot", "Rotberge"],
    },
    {
      inputType: Inputs.Checkbox,
      name: "isMine",
      label: "Is Your Soul Mine",
    },
  ],
}

//  form Activity {
//    title {
//      input: Text,
//      label: 'Title your ride',
//      type: Text
//    },
//    description {
//      input: Dropdown,
//      label: 'Description',
//      type: Text
//    },
//    type {
//      defaultValue: "Run",
//      input: Dropdown,
//      label: 'Activity Type',
//      options: [
//        // Foot Sports
//        'Run',
//        'Trail Run',
//        'Virtual Run',
//        'Walk',
//        'Hike',
//        'Wheelchair',
//        'Ride',
//        'Mountain Bike Ride',
//        'Gravel Ride',
//        'Virtual Ride',
//        'Swim',
//        'Surf'
//      ],
//      type: Text
//    },
//    ...

// Inputs Required Props
// Name, Type, inputType,
export const ActivityFormData = {
  name: "activity",
  inputs: [
    {
      inputType: Inputs.Text,
      label: "Title your ride",
      name: "title",
    },
    {
      inputType: Inputs.Text,
      label: "Description",
      name: "description",
    },
    {
      inputType: Inputs.Dropdown,
      defaultValue: "Run",
      label: "Activity Type",
      name: "type",
      options: [
        // Foot Sports
        "Run",
        "Trail Run",
        "Virtual Run",
        "Walk",
        "Hike",
        // Cycle Sports
        "Wheelchair",
        "Ride",
        "Mountain Bike Ride",
        "Gravel Ride",
        "Virtual Ride",
        // Water Sports
        "Swim",
        "Surf",
      ],
    },
    // {
    //   // Array / Multi select
    //   inputType: Inputs.Images,
    //   name: "images",
    //   unique: false,
    // },
    // New Object - Activity Stats
    {
      icon: "date",
      inputType: Inputs.Date,
      label: "Date",
      name: "date",
    },
    {
      icon: "clock",
      inputType: Inputs.Time,
      label: "Time",
      name: "time",
    },
    {
      icon: "clock", // require icon type file Icon.Clock
      inputType: Inputs.Dropdown,
      label: "Duration",
      name: "duration",
      defaultValue: 1,
      options: Array.from(Array(100).keys()),
      // type : Duration?
      // hours: Array.fill(24),
      // minutes: Array.fill(60),
      // seconds: Array.fill(60)
    },
    {
      icon: "google-pin",
      inputType: Inputs.Dropdown,
      defaultValue: 1,
      label: "Distance",
      name: "distance",
      options: Array.from(Array(100).keys()),
    },
    // New Object Type : Details
    {
      icon: "graph",
      inputType: Inputs.Dropdown,
      label: "Ride Type",
      name: "rideType",
      defaultValue: "None",
      options: ["None", "Long Run", "Workout", "Race", "Commute"],
    },
    {
      icon: "shoe",
      inputType: Inputs.Dropdown,
      label: "Shoe",
      name: "shoe",
      defaultValue: 1,
      options: Array.from(Array(100).keys()),
    },
    // New Object -- Visibility
    {
      icon: "planet-earth",
      inputType: Inputs.Dropdown,
      label: "Visibility",
      name: "visibility",
      defaultValue: "Everyone",
      options: ["Everyone", "Followers", "Only You"],
    },
    // {
    //   errors: errors.visibility,
    //   icon: "planet-earth", // require icon type file Icon.PlanetEarth
    //   inputType: Inputs.Checkbox,
    //   label: "Don't publish to Home or Club feeds",
    //   name: "whoCanSee",
    //   unique: false, // should be by default
    // },
  ],
}

export const MedicalConsent = {
  name: "consent",
  inputs: [
    {
      inputType: Inputs.Text,
      label: "Emergency Contact",
      name: "emergencyContact",
    },
    {
      //  Array / Multi select
      inputType: Inputs.Text,
      label: "Authorized Children",
      name: "authorizedChild",
    },
    {
      inputType: Inputs.Text,
      label: "Our family physician",
      name: "familyPhysician",
    },
    {
      // Address Field // autocomplete
      inputType: Inputs.Text,
      label: "His/her address is",
      name: "address",
    },
    {
      icon: "phone",
      inputType: Inputs.PhoneNumber,
      label: "His/her telephone # is",
      name: "herPhoneNumber",
      type: "tel",
    },
    {
      inputType: Inputs.Text,
      label: "Allergies",
      name: "allergies",
    },
    {
      icon: "phone",
      inputType: Inputs.PhoneNumber,
      label: "Contact me immediately at",
      name: "phoneNumber",
      type: "tel",
    },
    // Section: Signed By
    // Could be an object to simplify naming
    {
      icon: "signature",
      inputType: Inputs.Text,
      label: "Name",
      name: "signedByName",
    },
    {
      icon: "address",
      inputType: Inputs.Text,
      label: "Address",
      name: "signedByAddress",
    },
    {
      icon: "phone",
      inputType: Inputs.PhoneNumber,
      label: "Telephone",
      name: "signedByPhoneNumber",
    },
    {
      icon: "calendar",
      inputType: Inputs.Date,
      label: "Date",
      name: "signedByDate",
    },
  ],
}

export const Orders = {
  name: "orders",
  inputs: [
    {
      inputType: Inputs.Text,
      label: "Customer",
      name: "customer",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Shipping Address",
      name: "shippingAddress",
      type: "text",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Order Reference",
      name: "orderReference",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Date,
      label: "Date",
      name: "date",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Date,
      label: "Payment Term",
      name: "paymentTerm",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Pricelist",
      name: "pricelist",
      type: "text",
      unique: false,
    },
    {
      // items : array of some object ( item )
      inputType: Inputs.Text,
      label: "Item",
      name: "itemName",
      type: "text",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Quantity",
      name: "itemQuantity",
      type: "text",
      unique: false,
    },
    {
      // Float
      inputType: Inputs.Text,
      label: "Unit Price",
      name: "unitPrice",
      type: "text",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Discount",
      name: "itemDiscount",
      type: "text",
      unique: false,
    },
    {
      // Float
      inputType: Inputs.Text,
      label: "Price",
      name: "itemPrice",
      type: "text",
      unique: false,
    },
    {
      // end of ( item ) // Number
      inputType: Inputs.Text,
      label: "Subtotal",
      name: "subtotal",
      type: "text",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Taxes",
      name: "taxes",
      type: "text",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Total",
      name: "total",
      type: "text",
      unique: false,
    },
  ],
}

export const MedicalReport = {
  name: "medical",
  inputs: [
    {
      inputType: Inputs.Checkbox,
      label: "First Medical Report of Accident or Occupational Disease",
      name: "isFirstMedicalReport",
      unique: false,
    },
    {
      inputType: Inputs.Checkbox,
      label: "Medical Progress Report",
      name: "isMedicalProgress",
      unique: false,
    },
    {
      // Number
      inputType: Inputs.Text,
      label: "Medicare #",
      name: "medicalNumber",
      type: "text",
      unique: false,
    },
    {
      // number
      inputType: Inputs.Text,
      label: "Claim #",
      name: "claimNumber",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Date,
      label: "Visit date",
      name: "visitDate",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Time,
      label: "Time",
      name: "visitTime",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Last Name",
      name: "lastName",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "First Name",
      name: "firstName",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Date of Birth",
      name: "dateOfBirth",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Address",
      name: "Address",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "City/Town",
      name: "city",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Province",
      name: "province",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.PostalCode,
      label: "Postal code",
      name: "postalCode",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.PhoneNumber,
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Date,
      label: "Date of incident",
      name: "dateOfIncident",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Employer",
      name: "employer",
      type: "text",
      unique: false,
    },
    {
      inputType: Inputs.Text,
      label: "Occupation",
      name: "occupation",
      type: "text",
      unique: false,
    },
  ],
}

const ReportFormData = {
  name: "report",
  inputs: [
    {
      inputType: Inputs.Text,
      label: "Project Name",
      name: "projectName",
    },
    {
      inputType: Inputs.Date,
      label: "Report Date",
      name: "reportName",
    },
    {
      inputType: Inputs.Text,
      label: "Location in Structure",
      name: "location",
    },
    {
      inputType: Inputs.Text,
      label: "Weld I.D",
      name: "weldId",
    },
    {
      inputType: Inputs.Text,
      label: "Material Thickness",
      name: "materialThickness",
    },
    {
      inputType: Inputs.Text,
      label: "Weld Joint AWS",
      name: "weldJointAws",
    },
    {
      inputType: Inputs.Text,
      label: "Welding Process",
      name: "weldingProcess",
    },
    {
      inputType: Inputs.Text,
      label: "Indication Number",
      name: "indicationNumber",
    },
    {
      inputType: Inputs.Text,
      label: "Transducer Angle",
      name: "transducerAngle",
    },
    {
      inputType: Inputs.Dropdown,
      label: "From Face",
      name: "fromFace",
      defaultValue: "Top",
      options: ["Top", "End", "Side"],
    },
    {
      inputType: Inputs.Text,
      label: "Leg",
      name: "leg",
    },
  ],
}

const toDatabase = [
  ReportFormData,
  ActivityFormData,
  ProfileFormData,
  MedicalConsent,
  MedicalReport,
  Orders,
]

export default toDatabase
