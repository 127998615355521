import ThemeProvider from "./templates/ThemeProvider"
import { addWrappingComponentToComponent } from "../file_system/addComponentToComponent"
import {
  CommonRouterDirectory,
  LayoutsDirectory,
  SourceDirectory,
} from "../file_system/Directories"
import { writeFile, createFile } from "../file_system/FileSystemService"
import { CapitalizeFirstLetter } from "../forms/FormHelpers"
import BasePage from "./templates/BasePage"
import BaseTablePage from "./templates/BaseTablePage"
import Root from "./templates/Root"
import Router from "./templates/Router"
import RouterProvider from "./templates/RouterProvider"

export const generateLayout = async (directoryHandle, webApplication) => {
  await generateNavigationBar(directoryHandle, webApplication.forms)
  await generateTheme(directoryHandle, webApplication.colors)
}

const generateTheme = async (directoryHandle, colors) => {
  await createTheme(directoryHandle, colors)
}

const createTheme = async (directoryHandle, colors) => {
  await LayoutsDirectory(directoryHandle).then((directory) =>
    createFile(directory, "theme.js").then((file) =>
      writeFile(file, ThemeProvider.template(colors))
    )
  )

  await SourceDirectory(directoryHandle).then((directory) => {
    directory
      .getFileHandle("App.js")
      .then((fileHandle) =>
        addWrappingComponentToComponent(fileHandle, ThemeProvider)
      )
  })
}

export const generateNavigationBar = async (directoryHandle, routes) => {
  await createRouter(directoryHandle, routes)
  await createRoot(routes, directoryHandle)
  await createRouterProvider(directoryHandle)

  await createHomePage(directoryHandle)
  routes.forEach(async (route) => {
    await createTablePage(route, directoryHandle)
  })
}

const createRouter = async (directoryHandle, routes) =>
  CommonRouterDirectory(directoryHandle).then((directory) =>
    createFile(directory, "Router.js").then((file) =>
      writeFile(file, Router(routes))
    )
  )

const createRouterProvider = async (directoryHandle) => {
  await CommonRouterDirectory(directoryHandle)
    .then((directory) =>
      createFile(directory, RouterProvider.name.concat(".jsx"))
    )
    .then((fileHandle) => writeFile(fileHandle, RouterProvider.template()))

  await SourceDirectory(directoryHandle).then((directory) => {
    directory
      .getFileHandle("App.js")
      .then((fileHandle) =>
        addWrappingComponentToComponent(fileHandle, RouterProvider)
      )
  })
}

const createRoot = async (routes, directoryHandle) => {
  CommonRouterDirectory(directoryHandle).then((directory) =>
    createFile(directory, Root.name.concat(".jsx")).then((file) =>
      writeFile(file, Root.template(routes))
    )
  )

  // await SourceDirectory(directoryHandle)
  //   .then((directory) => {
  //     directory.getFileHandle('App.js').then(fileHandle =>
  //       addMostInnerChildToComponent(fileHandle, Root) // hard to do
  //     )
  //   })
}

const createHomePage = async (directoryHandle) => {
  SourceDirectory(directoryHandle).then((directory) =>
    directory
      .getDirectoryHandle("home", { create: true })
      .then((directory) =>
        createFile(
          directory,
          `${CapitalizeFirstLetter("home").concat("Page")}.jsx`
        ).then((file) => writeFile(file, BasePage({ name: "Home" })))
      )
  )
}

const createTablePage = async (route, directoryHandle) => {
  SourceDirectory(directoryHandle).then((directory) =>
    directory
      .getDirectoryHandle(route.name, { create: true })
      .then((directory) =>
        createFile(
          directory,
          `${CapitalizeFirstLetter(route.name).concat("Page")}.jsx`
        ).then((file) => writeFile(file, BaseTablePage(route)))
      )
  )
}
