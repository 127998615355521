export const WebAppDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("web", { create: true })

export const SynchronizerDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("synchronizer", {
    create: true,
  })

export const PublicDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("public", { create: true })

export const GithubDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle(".github", { create: true })

export const GithubWorkflowDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("workflows", {
    create: true,
  })

export const SourceDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("src", { create: true })

export const CommonDirectory = async (projectBaseDirectory) =>
  projectBaseDirectory.getDirectoryHandle("common", { create: true })

export const ComponentsDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("components", { create: true })

export const LayoutsDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory)
    .then((directory) => CommonDirectory(directory))
    .then((directory) =>
      directory.getDirectoryHandle("layout", { create: true })
    )

export const InputsDirectory = async (componentsDirectory) =>
  await componentsDirectory.getDirectoryHandle("inputs", { create: true })

export const ComonComponentsInputDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory)
    .then((directory) => CommonDirectory(directory))
    .then((directory) => ComponentsDirectory(directory))
    .then((directory) => FormsDirectory(directory))
    .then((directory) => InputsDirectory(directory))

export const FormsDirectory = async (componentsDirectory) =>
  await componentsDirectory.getDirectoryHandle("forms", { create: true })

export const ComonComponentsFormDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory)
    .then((directory) => CommonDirectory(directory))
    .then((directory) => ComponentsDirectory(directory))
    .then((directory) => FormsDirectory(directory))

export const HelpersDirectory = async (projectBaseDirectory) =>
  await projectBaseDirectory.getDirectoryHandle("components", { create: true })

export const CommonHelpersDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory)
    .then((directory) => CommonDirectory(directory))
    .then((directory) => HelpersDirectory(directory))

export const ComonComponentsTableDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory)
    .then((directory) => CommonDirectory(directory))
    .then((directory) => ComponentsDirectory(directory))
    .then((directory) => TableDirectory(directory))

export const TableDirectory = async (componentsDirectory) =>
  await componentsDirectory.getDirectoryHandle("tables", { create: true })

export const CommonDatabaseDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory).then((directory) =>
    DatabaseDirectory(directory)
  )

export const DatabaseDirectory = async (projectBaseDirectory) =>
  projectBaseDirectory.getDirectoryHandle("database", { create: true })

export const RootValueDirectory = async (databaseDirectory) =>
  databaseDirectory.getDirectoryHandle("rootValue", { create: true })

export const CommonRouterDirectory = async (projectBaseDirectory) =>
  await SourceDirectory(projectBaseDirectory).then((directory) =>
    directory.getDirectoryHandle("router", { create: true })
  )
