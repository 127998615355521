const IndexedDBReplication = {
  imports: [
    `import { pullQueryBuilderFromRxSchema, pullStreamBuilderFromRxSchema, pushQueryBuilderFromRxSchema, RxDBReplicationGraphQLPlugin } from 'rxdb/plugins/replication-graphql'`,
    `import { filter } from "rxjs"`,
  ],
  template: (props) => template(props),
}

// should merge replication part into main function
// maybe based on lastIndex of 'collections'
// increment + 1 and then paste .syncGraphQL

// should merge imports
const template = (props) => `
import { addRxPlugin, createRxDatabase } from "rxdb";
import { getRxStoragePouch, addPouchPlugin } from 'rxdb/plugins/pouchdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import schemas from "./schemas"
import { pullQueryBuilderFromRxSchema, pullStreamBuilderFromRxSchema, pushQueryBuilderFromRxSchema, RxDBReplicationGraphQLPlugin } from 'rxdb/plugins/replication-graphql' 
${
  props.isAttachmentsRequired
    ? "import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';"
    : ""
}

addPouchPlugin(require('pouchdb-adapter-idb'));
addRxPlugin(RxDBReplicationGraphQLPlugin);
${props.isAttachmentsRequired ? "addRxPlugin(RxDBAttachmentsPlugin);" : ""}
addRxPlugin(RxDBDevModePlugin);

const initialize = async () => {
  const db = await createRxDatabase({
    name: 'placeholder',
    storage: getRxStoragePouch('idb'),
    ignoreDuplicate: true
  });

  schemas.forEach(async schema => {
    const collection = await db.addCollections(schema)
    const batchSize = 50;

    const collectionName = Object.keys(collection)[0]
    
    const pullQueryBuilder = pullQueryBuilderFromRxSchema(
      collectionName,
      schema[collectionName],
      schema
    )

    const pushQueryBuilder = pushQueryBuilderFromRxSchema(
      collectionName,
      schema[collectionName],
    );

    const pullStreamBuilder = pullStreamBuilderFromRxSchema(
      collectionName,
      schema[collectionName],
    );

    const replicationState = collection[collectionName].syncGraphQL({
      url: {
          http: process.env.REACT_APP_SYNCHRONIZER_URL + '/graphql',
          // ws: 'ws://example.com/subscriptions' // <- The websocket has to use a different url.
      },
      push: {
        batchSize,
        queryBuilder: pushQueryBuilder
      },
      headers: {
          Authorization: 'Bearer abcde...',
      },
      pull: {
        batchSize,
        queryBuilder: pullQueryBuilder,
        streamQueryBuilder: pullStreamBuilder,
      },
      deletedField: 'deleted'
    })

    // show replication-errors in logs
    replicationState.error$.subscribe(err => {
        console.error('replication error:');
        console.dir(err);
    });
  })

  return db;
};

export default initialize
`

export default IndexedDBReplication
