import { useEffect } from "react"
import {
  CARDS_COLOR,
  SECONDARY_MAIN_DARK,
  SUCCESS,
  TEXT_MAIN,
} from "../common/layout/theme"

const {
  createCognitiveServicesSpeechServicesPonyfillFactory,
  createDirectLine,
  renderWebChat,
} = window.WebChat

const styleOptions = {
  backgroundColor: CARDS_COLOR,
  bubbleBorderColor: CARDS_COLOR,
  bubbleBackground: "#1e1e1e",
  bubbleBorderRadius: 4,
  bubbleFromUserBackground: SECONDARY_MAIN_DARK,
  bubbleFromUserBorderColor: SECONDARY_MAIN_DARK,
  bubbleFromUserBorderStyle: "solid",
  bubbleFromUserBorderWidth: 1,
  bubbleFromUserTextColor: TEXT_MAIN,
  bubbleTextColor: TEXT_MAIN,
  paddingRegular: 14,
  rootHeight: "78vh",
  sendBoxBackground: CARDS_COLOR,
  // sendBoxButtonColorOnHover: SUCCESS,
  sendBoxTextColor: TEXT_MAIN,
  sendBoxTextWrap: true,
  suggestedActionBackgroundColor: SECONDARY_MAIN_DARK,
  suggestedActionBorderColor: CARDS_COLOR,
  suggestedActionBorderRadius: 20,
  bubbleMaxWidth: "900px",
  // hideUploadButton: true,
  //sendBoxBackground: "rgb(42, 42, 42)", // Customize the send box background
  // sendBoxButtonColor: "blue", // Customize the send button color
}

const Webchat = () => {
  async function fetchCredentials() {
    const res = await fetch(
      `${process.env.REACT_APP_TRON_URL}/api/authorizationtoken`
    )

    if (res.ok) {
      const tokenData = await res.json()
      return {
        authorizationToken: tokenData.token,
        region: "canadacentral",
      }
    } else {
      throw new Error(
        "Failed to retrieve authorization token for Cognitive Services."
      )
    }
  }

  // Helper function for fetching network resource as JSON
  async function fetchJSON(url, options = {}) {
    const res = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        accept: "application/json",
      },
    })

    if (!res.ok) {
      throw new Error(`Failed to fetch JSON from server due to ${res.status}`)
    }

    return await res.json()
  }

  useEffect(() => {
    const asyncFunc = async () => {
      const { token } = await fetchJSON(
        `${process.env.REACT_APP_TRON_URL}/api/directline/token`
      )

      renderWebChat(
        {
          directLine: createDirectLine({
            token: token,
          }),
          // locale: "en-US",
          webSpeechPonyfillFactory:
            createCognitiveServicesSpeechServicesPonyfillFactory({
              credentials: fetchCredentials,
            }),
          styleOptions,
        },
        document.getElementById("webchat")
      )
    }
    asyncFunc()
  }, [])

  return <div id="webchat" />
}

export default Webchat
