import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  Card,
  CardActions,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import TextInput from "../common/components/forms/inputs/TextInput"
import { PINK } from "../themes/theme"
import { CARDS_COLOR } from "../common/layout/theme"
import AddIcon from "@mui/icons-material/Add"
import { Fragment, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"

import FormColumnInformation from "./FormColumInformations"

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "500px",
  bgcolor: "card.light",
  border: "1px solid " + PINK,
  boxShadow: 24,
  p: 4,
}

const formatData = (data) => {
  const formattedData = { inputs: [], name: "" }

  // Loop through each key in the data object
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      // Check if the key is related to inputs
      if (
        key.startsWith("name_") ||
        key.startsWith("type_") ||
        key.startsWith("label_")
      ) {
        const index = key.split("_").pop() // Get the index from the key
        const fieldProperty = key.split("_")[0] // Get the property name (field_name or input_type)

        // Create a new field object if it doesn't exist
        formattedData.inputs[index] = formattedData.inputs[index] || {}
        // Assign the value to the appropriate property in the field object
        formattedData.inputs[index][fieldProperty] = data[key]
      } else if (key.startsWith("inputs_")) {
        // If the key is related to options
        const [inputsText, inputIndex, optionsText, optionsIndex] =
          key.split("_") // Get the option index from the key

        // Ensure inputs[inputIndex] exists
        if (!formattedData.inputs[inputIndex]) {
          formattedData.inputs[inputIndex] = {}
        }

        // Ensure inputs[inputIndex].options exists as an array
        if (!formattedData.inputs[inputIndex].options) {
          formattedData.inputs[inputIndex].options = []
        }

        // Push the option value to the options array
        formattedData.inputs[inputIndex].options.push(data[key])
      } else {
        // For other properties, assign them directly
        formattedData[key] = data[key]
      }
    }
  }

  // Filter out any undefined entries in the inputs array
  formattedData.inputs = formattedData.inputs.filter((field) => field)

  return formattedData
}

export const renderAddButton = (onClick, size) => (
  <Card sx={{ width: size, height: size, backgroundColor: CARDS_COLOR }}>
    <CardActions
      sx={{
        padding: 0,
        minWidth: size,
        height: size,
        backgroundColor: CARDS_COLOR,
        display: "flex",
        justifyContent: "center", // Center horizontally
      }}
    >
      <IconButton
        sx={{
          textAlign: "center",
          backgroundColor: CARDS_COLOR,
        }}
        color="text"
        size="small"
        edge="start"
        aria-label="menu"
        onClick={() => onClick(true)}
      >
        <AddIcon />
      </IconButton>
    </CardActions>
  </Card>
)

const templates = [
  {
    name: "person",
    inputs: [
      {
        type: "Text",
        name: "first_name",
        label: "Prénom",
      },
      {
        type: "Text",
        name: "last_name",
        label: "Nom de famille",
      },
      {
        type: "Text",
        name: "email",
        label: "Courriel",
      },
      {
        type: "PhoneNumber",
        name: "phone_number",
        label: "Numéro de téléphone",
      },
    ],
  },
  {
    name: "election",
    inputs: [
      {
        type: "Text",
        name: "name",
        label: "Titre de l'élection",
      },
      {
        type: "Text",
        name: "description",
        label: "Description",
      },
      {
        type: "Dropdown",
        name: "status",
        label: "Statut",
        options: [
          "configuration",
          "registration",
          "notice",
          "voter",
          "verification",
          "tally",
          "post-tally",
          "reserved",
        ],
      },
      {
        type: "Text", // Should be integer with default and min
        name: "notice_interval_hours",
        label: "Notice interval hours",
      },
      {
        type: "Text", // Should be integer with default and min
        name: "participant_number",
        label: "Participants Number",
      },
      {
        type: "Date", // Should be integer with default and min
        name: "voting_start_datetime",
        label: "Notice interval hours",
      },
      {
        type: "Date", // Should be integer with default and min
        name: "voting_end_datetime",
        label: "Notice interval hours",
      },
      {
        type: "Text", // should be Radio Button choices and array of questions
        name: "question_one",
        label: "Question 1",
      },
      {
        type: "Text",
        name: "question_two",
        label: "Question 2",
      },
    ],
  },
  {
    name: "organization",
    inputs: [
      {
        type: "Text",
        name: "name",
        label: "Nom de l'organisation",
      },
      {
        type: "Text",
        name: "description",
        label: "Description",
      },
      {
        type: "Image",
        name: "image",
        label: "Image",
      },
      {
        type: "Dropdown",
        name: "type",
        label: "Type d'organisation",
        options: ["Type 1", "Type 2", "Type 3"],
      },
      {
        type: "Checkbox",
        name: "is_face_image_required",
        label: "Photo du visage",
      },
      {
        type: "Checkbox",
        name: "is_id_card_required",
        label: "Carte d'identité",
      },
      {
        type: "Checkbox",
        name: "is_member_card_required",
        label: "Carte de membre",
      },
      {
        type: "Checkbox",
        name: "is_password_required",
        label: "Mot de passe",
      },
      {
        type: "Text",
        name: "password",
        label: "Mot de passe",
      },
    ],
  },
  {
    name: "activity",
    inputs: [
      {
        type: "Text",
        label: "Title your ride",
        name: "title",
      },
      {
        type: "Dropdown",
        defaultValue: "Run",
        name: "type",
        label: "Activity Type",
        options: [
          "Run",
          "Trail Run",
          "Virtual Run",
          "Walk",
          "Hike",
          // Cycle Sports
          "Wheelchair",
          "Ride",
          "Mountain Bike Ride",
          "Gravel Ride",
          "Virtual Ride",
          // Water Sports
          "Swim",
          "Surf",
        ],
      },
      {
        type: "Date", // Should be integer with default and min
        label: "Date",
        name: "date",
      },
      {
        type: "Dropdown", // Should be integer with default and min
        label: "Duration",
        name: "duration",
        options: Array.from(Array(100).keys()),
      },
      {
        type: "Dropdown", // Should be integer with default and min
        label: "Distance",
        name: "distance",
        options: Array.from(Array(100).keys()),
        defaultValue: 1,
      },
      {
        type: "Dropdown", // Should be integer with default and min
        label: "Ride Type",
        name: "ride_type",
        options: ["None", "Long Run", "Workout", "Race", "Commute"],
      },
      {
        type: "Dropdown", // should be Radio Button choices and array of questions
        name: "shoe",
        label: "Shoe",
        options: ["shoe 1", "shoe 2"],
      },
      {
        type: "Dropdown",
        label: "Visibility",
        name: "visibility",
        options: ["Everyone", "Followers", "Only You"],
        defaultValue: "Everyone",
      },
    ],
  },
  {
    name: "consent",
    inputs: [
      {
        type: "Text",
        label: "Emergency Contact",
        name: "emergency_contact",
      },
      {
        type: "Text",
        label: "Authorized Children",
        name: "authorized_child",
      },
      {
        type: "Text",
        label: "Our family physician",
        name: "family_physician",
      },
      {
        type: "Text",
        label: "His/her address is",
        name: "address",
      },
      {
        type: "PhoneNumber",
        label: "His/her telephone # is",
        name: "her_phone_number",
      },
      {
        type: "Text",
        label: "Allergies",
        name: "allergies",
      },
      {
        type: "PhoneNumber",
        label: "Contact me immediately at",
        name: "phone_number",
      },
      {
        type: "Text",
        label: "Signature Name",
        name: "signature_name",
      },
      {
        type: "Text",
        label: "Signature Address",
        name: "signature_address",
      },
      {
        type: "Date",
        label: "Signature Date",
        name: "signature_date",
      },
    ],
  },
  {
    name: "report",
    inputs: [
      {
        type: "Text",
        label: "Project Name",
        name: "project_name",
      },
      {
        type: "Date",
        label: "Report Name",
        name: "report_name",
      },
      {
        type: "Text",
        label: "Weld I.D.",
        name: "weld_id",
      },
      {
        type: "Text",
        label: "Material Thickness",
        name: "material_thickness",
      },
      {
        type: "Text",
        label: "Weld Joint AWS",
        name: "weld_joint_aws",
      },
      {
        type: "Text",
        label: "Welding Process",
        name: "welding_process",
      },
      {
        type: "Text",
        label: "Indication Number",
        name: "indication_number",
      },
      {
        type: "Text",
        label: "Transducer Angle",
        name: "transducer_angle",
      },
      {
        type: "Dropdown",
        label: "From Face",
        name: "from_face",
        options: ["Top", "End", "Side"],
        defaultValue: "Top",
      },
      {
        type: "Text",
        label: "Leg",
        name: "leg",
      },
    ],
  },
  //{
  //  name: "ballot",
  //  inputs: [
  //    // ref Voter_id / or Person ??
  //    // ref Election_id
  //    // ref Question_id ??
  //    {
  //      type: "List",
  //      name: "questions",
  //      label: "Questions",
  //    },
  //    {
  //      type: "List",
  //      name: "answers",
  //      label: "Réponse",
  //    },
  //    {
  //      type: "Checkbox",
  //      name: "confirm_check",
  //      label:
  //        "Par la présente, je confirme que je soumets volontiers ce bulletin de vote.",
  //    },
  //  ],
  //},
]

const FormForm = ({ setIsOpen, onComplete }) => {
  const [formItemsNumber, setFormItemsNumber] = useState(1)

  const { register, getValues } = useForm()

  const [isNameAllowed, setIsNameAllowed] = useState(true)
  const [nameHelperText, setNameHelperText] = useState("")
  const [formName, setFormName] = useState("")

  const handleOnSubmit = async () => {
    let data = getValues()
    data.name = data.name.toLowerCase()
    if (data.name != "group") {
      // illegal postgresql table name
      const requestData = formatData(data)
      onComplete(requestData)
      setIsOpen(false)
    } else {
      setIsNameAllowed(false)
      setNameHelperText("group isn't supported")
    }
  }

  const handleOnTemplateClick = (template) => {
    onComplete(template)
    setIsOpen(false)
  }

  return (
    <Box px={2} color="white">
      <form>
        <Box sx={ModalStyle}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} py={1.6}>
              <Typography color="white.main" variant="h6">
                Add a Form
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <IconButton size="large" onClick={() => setIsOpen(false)}>
                <CloseIcon fontSize="inherit" color="white" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12} key={2}>
            <Stack direction="row" spacing={1}>
              {templates.map((template, index) => (
                <Chip
                  key={index}
                  size="small"
                  color="secondary"
                  sx={{ cursor: "pointer" }}
                  label={template.name}
                  variant="outlined"
                  onClick={() => handleOnTemplateClick(template)}
                />
              ))}
            </Stack>
          </Grid>

          <Box my={2}>
            <Grid item xs={6}>
              <TextInput
                inputType="Text"
                label="Form Name"
                name="name"
                defaultValue={formName}
                register={register}
                errors={!isNameAllowed}
                errorText={nameHelperText}
              />
            </Grid>
          </Box>

          <Box mt={4}>
            <Box my={2}>
              <Typography color="white.main" variant="h6">
                Fields
              </Typography>
            </Box>
            {[...Array(formItemsNumber)].map((_, index) => (
              <Fragment key={index}>
                <FormColumnInformation index={index} register={register} />
              </Fragment>
            ))}
          </Box>

          <Grid item xs={4}>
            <Box pt={2}>
              {renderAddButton(
                () => setFormItemsNumber(formItemsNumber + 1),
                45
              )}
            </Box>
          </Grid>

          <Grid item xs={12} py={2}>
            <Button
              sx={{ width: "100%" }}
              color="secondary"
              size="large"
              onClick={handleOnSubmit}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default FormForm
