const DateInput = {
  name: 'DateInput',
  template: () => `import { TextField } from "@material-ui/core"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react"

const DateInput = ({ defaultValue, label, name, register }) => {
  const [value, setValue] = useState(dayjs())
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...register(name, { required: true })}
        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
        label={label}
        name={name}
        value={value}
        type="datetime-local"
        onChange={setValue}
      />   
    </LocalizationProvider>
  )
}

export default DateInput
`
}

export default DateInput