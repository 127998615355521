import { useContext, useState } from "react"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
  Zoom,
} from "@mui/material"
import { Box } from "@mui/system"
import { useParams } from "react-router"
import { useRxData } from "rxdb-hooks"
import { PINK, PRIMARY_MAIN, SECONDARY_MAIN } from "../themes/theme"
import AddIcon from "@mui/icons-material/Add"
import { CARDS_COLOR, SECONDARY_MAIN_DARK } from "../common/layout/theme"
import { mainBarWidth } from "../router/Root"
import CloseIcon from "@mui/icons-material/Close"
import WebApplicationForm from "../web_application/WebApplicationForm"
import { AuthContext } from "../App"
import DeploymentForm from "../deployment/DeploymentForm"
import { db } from "../database"
import Divider from "@mui/material/Divider"
import WebApplicationCard from "./WebApplicationCard"

const ProjectPage = () => {
  let { id } = useParams()
  const { state } = useContext(AuthContext)
  const [isWebAppFormOpen, setIsWebAppFormOpen] = useState(false)
  const [isServerFormOpen, setIsServerFormOpen] = useState(false)
  const [isDeploymentFormOpen, setIsDeploymentFormOpen] = useState(false)

  const [isDeploymentDetailOpen, setIsDeploymentDetailOpen] = useState(false)

  const { result: project, isFetching: isProjectFetching } = useRxData(
    "project",
    (collection) => collection.find().where("id").equals(id),
    { json: true }
  )

  const { result: deployments, isFetching: isDeploymentsFetching } = useRxData(
    "deployment",
    (collection) => collection.find().where("project_id").equals(id),
    { json: true }
  )

  const { result: webApplications, isFetching: isWebApplicationsFetching } =
    useRxData(
      "web_application",
      (collection) => collection.find().where("project_id").equals(id),
      { json: true }
    )

  const isFetching =
    isProjectFetching || isDeploymentsFetching || isWebApplicationsFetching

  if (isFetching) {
    return <CircularProgress />
  }

  const onDeploy = async (deployment, project) => {
    const azure_cloud_connection = await db.collections.azure_cloud_connection
      .findOne()
      .where("id")
      .equals(deployment.azure_cloud_connection_id)
      .exec()

    const {
      subscription_id,
      managed_identity_id,
      managed_identity_secret,
      tenant_id,
    } = azure_cloud_connection

    const { access_token } = state.user

    const tata = await fetch(process.env.REACT_APP_TRON_URL + "/deploy", {
      method: "POST",
      body: JSON.stringify({
        project: project,
        deployment: deployment,
        github_access_token: access_token,
        azure_cloud_connection: {
          subscription_id,
          managed_identity_id,
          managed_identity_secret,
          tenant_id,
        },
      }),
    }).then((response) => response.json())
  }

  const renderAddButton = (onClick) => (
    <Card
      sx={{
        backgroundColor: CARDS_COLOR,
        maxWidth: 40,
        height: 40,
        paddingLeft: 0.2, // patch
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CardActions
        sx={{
          padding: 0,
          minWidth: 20,
          backgroundColor: CARDS_COLOR,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{
            textAlign: "center",
            backgroundColor: CARDS_COLOR,
          }}
          color="text"
          size="small"
          edge="start"
          aria-label="menu"
          onClick={() => onClick(true)}
        >
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  )

  const renderDeploymentCard = (deployment) => {
    return (
      <>
        <Card
          sx={{
            width: "80%",
            height: "80%",
            backgroundColor: CARDS_COLOR,
            cursor: "pointer",
          }}
          onClick={() => setIsDeploymentDetailOpen(true)} // not sure here
        >
          <CardContent>
            <Typography color="text.main" variant="h5">
              {deployment.environment_name.substring(0, 4)}
            </Typography>
          </CardContent>
        </Card>
        <Drawer
          sx={{
            flexShrink: 0,
            zIndex: 100000,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: CARDS_COLOR,
            },
          }}
          color="text.main"
          anchor="bottom"
          open={isDeploymentDetailOpen}
          onClose={() => setIsDeploymentDetailOpen(false)}
        >
          <Button
            size="large"
            color="success"
            onClick={() => onDeploy(deployment, project[0])}
          >
            Write Code to Github
          </Button>
          <Button
            size="large"
            color="success"
            onClick={() => console.log("baltazar")}
          >
            Write Code Locally
          </Button>
        </Drawer>
      </>
    )
  }

  return (
    <Box p={4} pr={8} ml={2}>
      <Box mb={3}>
        <Typography color={PRIMARY_MAIN} variant="h3">
          {project[0].name}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: PINK }} />
      <Grid container rowGap={4} px={0} py={4}>
        <Grid container>
          <Grid container alignItems="center" columnGap={4} rowGap={4}>
            <Grid item>
              <Card
                sx={{
                  backgroundColor: SECONDARY_MAIN,
                  borderRadius: "10px",
                  zIndex: 1202,
                  maxWidth: 200,
                  ":hover": {
                    backgroundColor: SECONDARY_MAIN_DARK,
                  },
                }}
                raised
                onClick={() => setIsWebAppFormOpen(true)}
              >
                <Box>
                  <CardContent>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} mb={8}>
                        <Typography color={PRIMARY_MAIN} variant="h6">
                          Applications
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions
                    sx={{
                      padding: 0,
                      height: 60,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <AddIcon color="text" />
                  </CardActions>
                </Box>
              </Card>
            </Grid>

            {webApplications.map((webApplication, index) => (
              <Grid item height={200} width={300} key={index}>
                <WebApplicationCard
                  webApplication={webApplication}
                  project={project[0]}
                />
              </Grid>
            ))}
          </Grid>

          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                backgroundColor: SECONDARY_MAIN,
              },
            }}
            anchor="right"
            color="primary"
            open={isWebAppFormOpen}
          >
            <Card
              sx={{
                backgroundColor: SECONDARY_MAIN,
                borderRadius: "10px",
                overflowY: "scroll",
                zIndex: 1202,
                maxWidth: 500,
              }}
              raised
            >
              <Box>
                <CardContent>
                  <Grid container justifyContent="end">
                    <Grid item xs={1.5}>
                      <IconButton
                        size="large"
                        onClick={() => setIsWebAppFormOpen(false)}
                      >
                        <CloseIcon fontSize="inherit" color="white" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <WebApplicationForm
                    onComplete={() => setIsWebAppFormOpen(false)}
                  />
                </CardContent>
              </Box>
            </Card>
          </Drawer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProjectPage
