import { CapitalizeFirstLetter } from "../../forms/FormHelpers"

const BaseTable = (name) => {
  const capitalizedName = CapitalizeFirstLetter(name)
  
  return (`
import { useState } from "react"
import { useRxData } from "rxdb-hooks"
import Table from "../common/components/tables/Table"
import FormSchema from "./${capitalizedName}FormSchema"

const ${capitalizedName}Table = () => {
  const [formInfo, ] = useState(FormSchema)

  const { result, isFetching } = useRxData(
    '${name}',
    collection => {
      return collection.find()
    }
  );

  if (isFetching) {
    return 'loading ...';
  }

  return (
    <>
      <Table
        columns={formInfo}
        data={result}
      />
    </>
  )
}

export default ${capitalizedName}Table
`
)}

export default BaseTable
