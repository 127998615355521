const PostgreSQLDatabase = {
  template: () => template(),
}

const template = () => `import pg from 'pg'

export let client; 
export const db = () => {
  client = new pg.Client({
    host: process.env.HOST,
    user: process.env.DB_USER,
    password: process.env.PASSWORD,
    database: process.env.DATABASE
  })
  client.connect() 
}

`

export default PostgreSQLDatabase
