import { uniqueBy } from "../common/base/ArrayHelpers"
import { SourceDirectory } from "../file_system/Directories"
import { createFile, writeFile } from "../file_system/FileSystemService"
import { createInputs } from "../inputs/InputService"
import { CapitalizeFirstLetter } from "./FormHelpers"
import BaseForm from "./templates/BaseForm"
import BaseFormSchema from "./templates/BaseFormSchema"
import LoginForm from "./templates/LoginForm"
import SignUpForm from "./templates/SignUpForm"

export const generateAllForms = async (directoryHandle, formsData) => {
  formsData.forEach(async (formData) => {
    await createFormSchema(formData, directoryHandle) // this useless right?
    await createForm(formData, directoryHandle)
  })
}

const createFormSchema = async (formData, directoryHandle) =>
  SourceDirectory(directoryHandle).then((directory) =>
    directory
      .getDirectoryHandle(formData.name, { create: true })
      .then((directory) =>
        createFile(
          directory,
          `${CapitalizeFirstLetter(formData.name).concat("FormSchema")}.jsx`
        ).then((file) => writeFile(file, BaseFormSchema(formData)))
      )
  )

const createForm = async (formData, directoryHandle) => {
  SourceDirectory(directoryHandle).then((directory) =>
    directory
      .getDirectoryHandle(formData.name, { create: true })
      .then((directory) =>
        createFile(
          directory,
          `${CapitalizeFirstLetter(formData.name).concat("Form")}.jsx`
        ).then((file) => writeFile(file, BaseForm(formData)))
      )
  )
}

const createCustomForm = async (name, directoryHandle) =>
  await createForm((BaseForm.name = name), directoryHandle)
export const addCustomForm = async (directoryHandle, name) =>
  await createCustomForm(name, directoryHandle)
export const addLoginForm = async (directoryHandle) =>
  await createForm(LoginForm, directoryHandle)
export const addSignUpForm = async (directoryHandle) =>
  await createForm(SignUpForm, directoryHandle)
