import { uniqueBy } from "../../common/base/ArrayHelpers"
import { renderInputs } from "../../inputs/InputHelpers"
import { CapitalizeFirstLetter } from "../FormHelpers"

const BaseForm = (data) => `import { useForm } from "react-hook-form";
import Button from "@mui/material/Button"
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useRxCollection } from "rxdb-hooks";
${uniqueBy(data.inputs, "type")
  .map(
    (input) =>
      `import ${input.type}Input from "../common/components/forms/inputs/${input.type}Input"`
  )
  .join("\n")}

const ${CapitalizeFirstLetter(data.name)}Form = () => {
  const collection = useRxCollection('${data.name}');

  const {
    control,
    register,
    handleSubmit
  } = useForm();
  
  // todo 
  // await document?.putAttachment({
  //  id: "logo.jpg",
  //  data: data.logo,
  //  type: "image/png",
  //})
  //

  const onSubmit = async (data) => await collection.insert({...data, id: Date.now().toString(), updatedAt: new Date().getTime() });
  
  return (
    <Box m={2} py={3} maxWidth={400}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} justifyItems="space-around">
          ${renderInputs(data.inputs)}
          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default ${CapitalizeFirstLetter(data.name)}Form
`

export default BaseForm
