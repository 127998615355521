import { useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import {
  Card,
  CardActions,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useRxCollection } from "rxdb-hooks"
import TextInput from "../common/components/forms/inputs/TextInput"
import CloseIcon from "@mui/icons-material/Close"
import { useParams } from "react-router"
import { createUniqueId } from "../common/utils/utilities"

const AzureCloudConnectionForm = ({ setIsOpen, setValue }) => {
  let { id } = useParams()
  const collection = useRxCollection("azure_cloud_connection")

  const { register, getValues } = useForm()

  const handleOnSubmit = async () => {
    const data = getValues() // { test: "test-input", test1: "test1-input" }
    const document = await collection.insert({
      ...data,
      project_id: id,
      id: createUniqueId(),
      updatedAt: new Date().getTime(),
    })

    setValue(document.id)
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Box px={2} color="white">
      <form>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} py={1.6}>
            <Typography color="white.main" variant="h6">
              Create an Azure Cloud Connection
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <IconButton size="large" onClick={() => setIsOpen(false)}>
              <CloseIcon fontSize="inherit" color="white" />
            </IconButton>
          </Grid>
        </Grid>

        <Box py={4}>
          <Grid item key={5} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter the Azure Subscription ID"
                name="subscription_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={6} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter my Managed Identity Id"
                name="managed_identity_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={7} xs={12}>
            <Box mb={2}>
              <TextInput
                inputType="Text"
                label="Enter my Managed Identity Secret"
                name="managed_identity_secret"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={8} xs={12}>
            <Box mb={4}>
              <TextInput
                inputType="Text"
                label="Enter the Tenant Id"
                name="tenant_id"
                register={register}
              />
            </Box>
          </Grid>

          <Grid item key={9} xs={12}>
            <Button
              sx={{ width: "100%" }}
              color="secondary"
              size="large"
              // type="submit"
              onClick={handleOnSubmit}
              variant="contained"
            >
              Create
            </Button>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default AzureCloudConnectionForm
