import React, { useEffect, useReducer, useState } from "react"
import axios from "axios"
import Styled from "styled-components"
import { Box } from "@mui/system"
import { SECONDARY_MAIN } from "../themes/theme"
import { OutlinedInput } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const DockerImageSearchResult = {
  is_automated: "is_automated",
  is_official: "is_official",
  pull_count: "pull_count",
  repo_name: "repo_name",
  repo_owner: "repo_owner",
  short_description: "short_description",
  star_count: "star_count",
}

const DockerImageSearchResultDTO = (searchResult) => {
  return {
    name: searchResult[DockerImageSearchResult.repo_name],
    description: searchResult[DockerImageSearchResult.short_description],
    owner: searchResult[DockerImageSearchResult.repo_owner],
    pullCount: searchResult[DockerImageSearchResult.pull_count],
    starCount: searchResult[DockerImageSearchResult.star_count],
    isAutomated: searchResult[DockerImageSearchResult.is_automated],
    isOfficial: searchResult[DockerImageSearchResult.is_official],
  }
}

function DockerHubSearch({ setImage, setSelectedImage }) {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])

  const inputStyle = {
    // MUI theming should be overrided in the MUI Theme Provider
    color: "white",
  }

  const search = async (query) => {
    return await axios
      .get(`${process.env.REACT_APP_TRON_URL}/search/docker/hub`, {
        params: {
          query,
        },
      })
      .then((response) =>
        setResults(response.data.map(DockerImageSearchResultDTO))
      )
      .catch((error) => {
        // handle the error
      })
  }

  const handleSearch = async (event) => {
    event.preventDefault()
    await search(query)
  }

  const handleOnClick = async (image) => {
    setImage(image)
    setQuery(image.name)
    setSelectedImage(image)
    await search("")
  }

  return (
    <>
      <OutlinedInput
        fullWidth
        color="text"
        type="text"
        value={query}
        variant="outlined"
        onChange={(e) => setQuery(e.target.value)}
        inputProps={{
          style: inputStyle,
        }}
        placeholder="Search Docker Hub"
        endAdornment={
          <SearchIcon
            color="text"
            onClick={handleSearch}
            sx={{ cursor: "pointer" }}
          />
        }
      />
      <List
        sx={{
          bgcolor: SECONDARY_MAIN,
        }}
      >
        {results.length > 0 &&
          results.map((image) => (
            <>
              <Box
                p={2}
                sx={{
                  border: "solid 1px #353535",
                  bgcolor: "#282828",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#282828",
                  },
                  width: "106%",
                }}
              >
                <ListItem key={image.name} onClick={() => handleOnClick(image)}>
                  {image.name}
                  <Box>{image.description}</Box>
                </ListItem>
              </Box>
            </>
          ))}
      </List>
    </>
  )
}

const List = Styled.ul`
  position: absolute;
  background-color: ${SECONDARY_MAIN};
  z-index: 100;
`

const ListItem = Styled.li`
  cursor: pointer;
  list-style-type: none;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  &:hover {
    background-color: #282828;
  }
`

export default DockerHubSearch
