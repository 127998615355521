const WebAppPackages = {
  name: "WebAppPackages",
  // dependencies: ["@material-ui/core@^4.12.4", "react"],
  template: (name) => `{
  "name": "${name.toLowerCase()}-app",
  "version": "1.0.0",
  "private": true,
  "dependencies": {
    "@emotion/react": "^11.10.4",
    "@emotion/styled": "^11.10.4",
    "@hookform/resolvers": "^2.9.10",
    "@material-ui/core": "^4.12.4",
    "@mui/icons-material": "^5.10.9",
    "@mui/lab": "^5.0.0-alpha.103",
    "@mui/material": "^5.10.6",
    "@mui/styles": "^5.11.11",
    "@mui/x-date-pickers": "^5.0.6",
    "axios": "^1.3.4",
    "dayjs": "^1.11.6",
    "env-cmd": "^10.1.0",
    "idb": "^7.1.0",
    "material-react-table": "1.3.2",
    "pouchdb-adapter-idb": "^7.3.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hook-form": "^7.38.0",
    "react-input-mask": "^2.0.4",
    "react-mic": "^12.4.6",
    "react-router": "^6.4.3",
    "react-router-dom": "^6.4.3",
    "react-speech-recognition": "^3.10.0",
    "react-spring": "^9.5.5",
    "rxdb": "13.8.2",
    "rxdb-hooks": "^4.0.3",
    "rxjs": "^7.5.7",
    "styled-components": "^5.3.8",
    "yup": "^0.32.11"
  },
  "devDependencies": {
    "@testing-library/jest-dom": "^5.14.1",
    "@testing-library/react": "^13.0.0",
    "@testing-library/user-event": "^13.2.1",
    "react-scripts": "5.0.1",
    "web-vitals": "^2.1.0"
  },
  "scripts": {
    "start": "react-scripts start",
    "build": "react-scripts build",
    "test": "react-scripts test",
    "eject": "react-scripts eject",
    "build:prod": "env-cmd -f .env.production react-scripts build"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
}`,
}

export default WebAppPackages
