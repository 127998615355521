import { CapitalizeFirstLetter } from "../../forms/FormHelpers"

const Router = (routes) => {
  return `import { createBrowserRouter } from "react-router-dom"
import Root from "./Root"
import HomePage from "../home/HomePage"
${routes
  .map(
    (route) =>
      `import ${CapitalizeFirstLetter(route.name)}Page from "../${
        route.name
      }/${CapitalizeFirstLetter(route.name)}Page"`
  )
  .join("\n")}

const router = () => createBrowserRouter([
  {
    path: "/",
    element: <Root><HomePage /></Root>,
  },${routes.map(
    (route) => `
  {
    path: "${route.name}",
    element:  <Root><${CapitalizeFirstLetter(route.name).concat(
      "Page"
    )}/></Root>,
  }`
  )}
]
)

export default router
`
}

export default Router
