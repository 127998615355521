import { addWrappingComponentToComponent } from "../file_system/addComponentToComponent"
import {
  CommonDatabaseDirectory,
  DatabaseDirectory,
  RootValueDirectory,
  SourceDirectory,
} from "../file_system/Directories"
import { writeFile, createFile } from "../file_system/FileSystemService"
import Inputs from "../inputs/Inputs"
import { buildRXDBSchema, isAttachmentsRequired } from "./SchemaBuilder"
import DatabaseProvider from "./templates/DatabaseProvider"
import GraphQLSchemaBuilder from "./templates/graphQL/GraphQLSchemaBuilder"
import IndexDefaultExport from "./templates/graphQL/IndexDefaultExport"
import RootValue, { RootValueHelper } from "./templates/graphQL/RootValue"
import NodeJSServer from "./templates/node-server/NodeJSServer"
import PostgreSQLDatabase from "./templates/node-server/PostgreSQLDatabase"
import IndexedDB from "./templates/rxdb/IndexedDB"
import IndexedDBReplication from "./templates/rxdb/IndexedDBReplication"
import Schemas from "./templates/schemas"

export const generateIndexedDBDatabases = async (directoryHandle, forms) => {
  const databasePath = await CommonDatabaseDirectory(directoryHandle)
  await createRXDBSchema(databasePath, forms)
  const inputs = forms.reduce((acc, form) => {
    return [...acc, ...form.inputs]
  }, [])
  await createDatabase(
    directoryHandle,
    IndexedDB,
    isAttachmentsRequired(inputs)
  )
  await addDatabaseProvider(directoryHandle)
}

const createPostgreSQLTableFile = async (
  serverDirectoryHandle,
  schema,
  forms
) => {
  const fileContent = schema
    .map((table) => {
      const tableName = Object.keys(table)[0]
      const tableColumns = Object.entries(
        Object.values(table)[0].schema.properties
      )

      const currentForm = forms.find((form) => form.name === tableName)
      const isTrue = currentForm.inputs.some(
        (input) => input.type === Inputs.Image
      )
      if (isTrue) {
        tableColumns.push(["image", { type: "image" }])
      }

      return `CREATE TABLE ${tableName} (
  ${tableColumns
    .map((column) => {
      if (column[0] === "id")
        return `id integer GENERATED ALWAYS AS IDENTITY PRIMARY KEY`
      else if (column[0] === "updatedAt") return `  updatedat bigint`
      else if (column[1].type === "boolean") return `  ${column[0]} boolean`
      else if (column[1].type === "image") return `  image bytea`
      else return `  ${column[0]} text`
    })
    .join(",\n")}
);`
        .concat("\n")
        .concat("\n")
    })
    .join("")

  await DatabaseDirectory(serverDirectoryHandle)
    .then((directory) => createFile(directory, "initDatabase.sql"))
    .then((fileHandle) => writeFile(fileHandle, fileContent))
}

export const generateNodeJSDatabase = async (
  frontend_directory,
  server_directory,
  forms
) => {
  const databasePath = await DatabaseDirectory(server_directory)
  const schema = await createRXDBSchema(databasePath, forms)

  await createPostgreSQLTableFile(server_directory, schema, forms)

  await buildGraphQLSchema(server_directory, forms)

  await DatabaseDirectory(server_directory)
    .then((directory) => createFile(directory, "database.js"))
    .then((fileHandle) => writeFile(fileHandle, PostgreSQLDatabase.template()))

  await createFile(server_directory, "index.js").then((fileHandle) =>
    writeFile(fileHandle, NodeJSServer.template())
  )

  await createDatabase(frontend_directory, IndexedDBReplication)
}

export const addSchemaFile = async (directoryHandle, formData) =>
  await createRXDBSchema(
    await CommonDatabaseDirectory(directoryHandle),
    formData
  )
export const addRXDBDatabase = async (directoryHandle) =>
  await createDatabase(directoryHandle, IndexedDB)
export const addSynchronizedRXDBDatabase = async (directoryHandle) =>
  await createDatabase(directoryHandle, IndexedDBReplication)
export const addDatabaseProvider = async (directoryHandle) =>
  await createDatabaseProvider(directoryHandle)

const createDatabase = async (
  directoryHandle,
  database,
  isAttachmentsRequired
) => {
  await CommonDatabaseDirectory(directoryHandle)
    .then((directory) => createFile(directory, `index.js`))
    .then((fileHandle) =>
      writeFile(fileHandle, database.template({ isAttachmentsRequired }))
    )

  return directoryHandle
}

const createRXDBSchema = async (directory, forms) => {
  const schema = forms.map(buildRXDBSchema)

  createFile(directory, "schemas.js").then((fileHandle) =>
    writeFile(fileHandle, Schemas(schema))
  )

  return schema
}

const createDatabaseProvider = async (directoryHandle) => {
  await CommonDatabaseDirectory(directoryHandle)
    .then((directory) =>
      createFile(directory, DatabaseProvider.name.concat(".jsx"))
    )
    .then((fileHandle) => writeFile(fileHandle, DatabaseProvider.template()))

  await SourceDirectory(directoryHandle).then((directory) => {
    directory
      .getFileHandle("App.js")
      .then((fileHandle) =>
        addWrappingComponentToComponent(fileHandle, DatabaseProvider)
      )
  })

  return directoryHandle
}

export const buildGraphQLSchema = async (directoryHandle, forms) => {
  await createRootValueHelper(directoryHandle)
  forms.forEach(
    async (form) => await createGraphQLRootValue(directoryHandle, form)
  )
  await createIndexDefaultExport(
    directoryHandle,
    forms.map((form) => form.name)
  )
  await createGraphQLSchemaBuilder(directoryHandle)
}

const createGraphQLRootValue = async (directoryHandle, form) => {
  return await DatabaseDirectory(directoryHandle).then((directory) =>
    RootValueDirectory(directory)
      .then((directory) => createFile(directory, `${form.name}.js`))
      .then((fileHandle) => writeFile(fileHandle, RootValue(form)))
  )
}

const createIndexDefaultExport = async (directoryHandle, fileNames) => {
  await DatabaseDirectory(directoryHandle).then((directory) =>
    RootValueDirectory(directory)
      .then((directory) => createFile(directory, `index.js`))
      .then((fileHandle) =>
        writeFile(fileHandle, IndexDefaultExport(fileNames))
      )
  )
}

const createRootValueHelper = async (directoryHandle) => {
  return await DatabaseDirectory(directoryHandle).then((directory) =>
    RootValueDirectory(directory).then((directory) =>
      createFile(directory, `RootValueHelpers.js`).then((fileHandle) =>
        writeFile(fileHandle, RootValueHelper())
      )
    )
  )
}

const createGraphQLSchemaBuilder = async (directoryHandle) => {
  return await DatabaseDirectory(directoryHandle).then((directory) =>
    createFile(directory, `GraphQLSchemaBuilder.js`).then((fileHandle) =>
      writeFile(fileHandle, GraphQLSchemaBuilder())
    )
  )
}
