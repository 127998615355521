export const NginxConfigurationFile = {
  name: "nginx.conf",
  template: ({ domainName }) => `worker_processes  1;

events {
    worker_connections  1024;
}

http {
    include       mime.types;
    default_type  application/octet-stream;

  server {
    listen 80;
    server_name ${domainName};

    root /usr/share/nginx/html;

    index index.html;
    error_page 404 /index.html;

    location / {
      try_files $uri $uri/ /index.html;
    }

    # optional: add caching headers for static assets
    #location ~* \\.(?:ico|css|js|gif|jpe?g|png|svg|woff|woff2|ttf|eot)$ {
    #  expires 1d;
    #  add_header Cache-Control "public";
    #}
  }
}`,
}
