import { Grid, IconButton, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import { Controller } from "react-hook-form"
import { Box } from "@mui/system"
import { CARDS_COLOR } from "../../../layout/theme"

const ImageInput = ({ control, document, name, rules, text }) => {
  const [image, setImage] = useState()
  useEffect(() => {
    const fetchData = async () => {
      if (document) {
        // null checks are for dummies
        const data = await document.getAttachment("logo.jpg").getData()
        setImage(data)
      }
    }

    fetchData().catch(console.error)
  }, [document])

  const handleOnChange = (e, field) => {
    setImage(e.target.files[0])
    field.onChange(e.target.files[0])
  }

  return (
    <Grid container alignItems="center" justifyContent="start">
      <Grid
        item
        sx={{
          maxWidth: 40,
          height: 40,
          backgroundColor: CARDS_COLOR,
        }}
      >
        {image && (
          <img
            src={URL.createObjectURL(image)}
            alt="toto"
            height="100%"
            width="100%"
          />
        )}
        {!image && (
          <Grid
            item
            xs={3}
            sx={{
              textAlign: "center",
            }}
          >
            {control && (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <label htmlFor="raised-button-file">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={(e) => handleOnChange(e, field)}
                    />
                    <IconButton variant="raised" component="span">
                      <AddIcon color="text" />
                    </IconButton>
                  </label>
                )}
                rules={rules}
              />
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          paddingLeft: 2,
          textAlign: "start",
        }}
      >
        <Box>
          <Typography variant="subtitle2" color="text.main">
            {text}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ImageInput
