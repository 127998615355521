  import { ComonComponentsTableDirectory, SourceDirectory } from "../file_system/Directories"
import { writeFile, createFile } from "../file_system/FileSystemService"
import { CapitalizeFirstLetter } from "../forms/FormHelpers";
import BaseTable from "./templates/BaseTable";
import Table from "./templates/Table";

export const generateAllTables = async (directoryHandle, tablesData) => {
  await createBaseTable(directoryHandle)
  tablesData.forEach(async tableData => {
    const sourceDirectory = await SourceDirectory(directoryHandle)
    await createTable(tableData, sourceDirectory)
  })
}

const createBaseTable = async (rootDirectory) => {
  const commonTableDirectory = await ComonComponentsTableDirectory(rootDirectory)
  createFile(commonTableDirectory, 'Table.jsx').then(file => 
    writeFile(file, Table())
  )
}

const createTable = async (tableData, sourceDirectory) => 
  sourceDirectory
    .getDirectoryHandle(tableData.name, { create: true })
    .then((directory) =>
      createFile(
        directory,
        `${CapitalizeFirstLetter(tableData.name).concat("Table")}.jsx`
      ).then((file) => writeFile(file, BaseTable(tableData.name)))
    )
  


export const addTable = async directoryHandle => await createTable(Table, directoryHandle)