import { Box, Card, CardContent, Typography } from "@mui/material"
import { CARDS_COLOR, CARDS_LIGHT } from "../../common/layout/theme"

export default function FeatureCard(props) {
  return (
    <Box mb={1} ml={1}>
      <Card
        sx={{
          backgroundColor: CARDS_COLOR,
          borderRadius: "10px",
          height: {
            xs: "200px", // Adjusted for mobile
            sm: "300px",
          },
        }}
        raised
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Icon with circle around it */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%", // Makes the Box circular
              backgroundColor: CARDS_LIGHT,
            }}
          >
            {props.icon}
          </Box>

          {/* Title Container with fixed height */}
          <Box
            py={2}
            sx={{
              height: {
                xs: "40px", // Adjusted for mobile
                sm: "60px",
              }, // Set a fixed height for the title container
              display: "flex", // Flexbox ensures alignment inside the box
              alignItems: "flex-start", // Aligns titles to the top
            }}
          >
            <Typography variant="h5" fontWeight="500" color="white.main">
              {props.title}
            </Typography>
          </Box>

          {/* Subtitle Container */}
          <Box
            sx={{
              flexGrow: 0, // Keeps the subtitle in place, unaffected by title size
              display: "flex",
              alignItems: "flex-start", // Aligns subtitle at the top of its box
            }}
          >
            <Typography variant="body2" color="white.main">
              {props.subtitle}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
