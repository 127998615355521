import { PublicDirectory } from "../file_system/Directories"
import { createFile, writeFile } from "../file_system/FileSystemService"
import Manifest from "./templates/Manifest"
import Html from "./templates/Html"

// Function to resize and crop a Blob
function resizeAndCropBlob(inputBlob, width, height) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")

    const blobURL = URL.createObjectURL(inputBlob)

    const image = new Image()
    image.src = blobURL

    image.onload = () => {
      // Calculate the aspect ratio of the input image
      const aspectRatio = image.width / image.height

      // Calculate the new dimensions while maintaining the aspect ratio
      let newWidth, newHeight
      if (aspectRatio > width / height) {
        newWidth = width
        newHeight = width / aspectRatio
      } else {
        newWidth = height * aspectRatio
        newHeight = height
      }

      // Set the canvas dimensions to the target size
      canvas.width = width
      canvas.height = height

      // Perform the resizing and cropping
      ctx.drawImage(image, 0, 0, newWidth, newHeight)

      // Convert the canvas content to a Blob
      canvas.toBlob(
        (outputBlob) => {
          resolve(outputBlob)
        },
        "image/png",
        0.9
      ) // Adjust the quality as needed
    }

    image.onerror = (err) => {
      reject(err)
    }
  })
}

export const generatePWA = async (webAppDirectory, logo, webApplication) => {
  const logo192 = await resizeAndCropBlob(logo, 192, 192)
  const logo512 = await resizeAndCropBlob(logo, 512, 512)

  addLogoTo(webAppDirectory, "logo192.png", logo192)
  addLogoTo(webAppDirectory, "logo512.png", logo512)

  addPwaManifestTo(webAppDirectory, webApplication.name)
}

const addPwaManifestTo = (webAppDirectory, webAppName) =>
  PublicDirectory(webAppDirectory).then((directory) => {
    createFile(directory, "manifest.json").then((file) =>
      writeFile(file, Manifest.template(webAppName))
    )
  })

export const addLogoTo = (rootDirectory, name, logo) =>
  PublicDirectory(rootDirectory).then((directory) => {
    createFile(directory, name).then((file) => writeFile(file, logo))
  })

export const addFaviconTo = (rootDirectory, logo) =>
  PublicDirectory(rootDirectory).then((directory) => {
    createFile(directory, "favicon.ico").then((file) => writeFile(file, logo))
  })
