import { useEffect, useState } from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { makeStyles } from "@mui/styles"
import { SECONDARY_MAIN } from "../../../layout/theme"

// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
// const styledIcon = () => <ArrowDropDownIcon style={{ color: "white" }} />

const useStyles = makeStyles((theme) => ({
  select: {
    "&:focus": {
      backgroundColor: "#333333",
    },
    "& .MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  icon: {
    fill: "white", // Change the arrow color
  },
}))

const DropdownInput = ({
  errors,
  label,
  options,
  register = () => {},
  name,
  onChange = () => {},
  defaultValue = "",
}) => {
  const [value, setValue] = useState(defaultValue)
  const classes = useStyles()

  const handleOnChange = (e) => {
    onChange(e.target.value)
    setValue(e.target.value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel style={{ color: "white" }}>{label}</InputLabel>
      <Select
        style={{
          color: "white",
        }}
        {...register(name, { required: true })}
        error={errors ? true : false}
        label={label} // for label width
        className={classes.select} // Apply custom styles to the Select component
        classes={{
          icon: classes.icon, // Apply custom styles to the arrow icon
        }}
        value={value}
        required
        onChange={handleOnChange}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option}
            style={{ color: "white", p: 0, backgroundColor: SECONDARY_MAIN }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropdownInput
