import { createBrowserRouter } from "react-router-dom"
import Root from "./Root"
import HomePage from "../home/HomePage"
import ProjectPage from "../project/ProjectPage"
import LoginPage from "../login/LoginPage"
import AboutPage from "../about/AboutPage"

const router = () =>
  createBrowserRouter([
    {
      path: "/project/:id",
      element: (
        <Root>
          <ProjectPage />
        </Root>
      ),
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/",
      element: (
        <Root>
          <HomePage />
        </Root>
      ),
    },
  ])

export default router
