const schemas = [
  {
    azure_cloud_connection: {
      schema: {
        title: "azure_cloud_connection",
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: {
          id: {
            type: "string",
            maxLength: 100,
          },
          updatedAt: {
            type: "number",
            minimum: 0,
            maximum: 1000000000000000,
            multipleOf: 1,
          },
          project_id: {
            type: "string",
            ref: "project",
          },
          subscription_id: {
            type: "string",
          },
          tenant_id: {
            type: "string",
          },
          managed_identity_id: {
            type: "string",
          },
          managed_identity_secret: {
            type: "string",
          },
        },
        attachments: {},
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  },
  {
    deployment: {
      schema: {
        title: "deployment",
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: {
          id: {
            type: "string",
            maxLength: 100,
          },
          updatedAt: {
            type: "number",
            minimum: 0,
            maximum: 1000000000000000,
            multipleOf: 1,
          },
          project_id: {
            type: "string",
            ref: "project",
          },
          environment_name: {
            type: "string",
          },
          azure_cloud_connection_id: {
            type: "string",
            ref: "azure_cloud_connection",
          },
          is_github_actions: {
            type: "boolean",
          },
          location: {
            type: "string",
          },
          domain_name: {
            type: "string",
          },
          web_application_id: {
            type: "string",
            ref: "web_application",
          },
          public_image_name: {
            type: "string",
          },
          terraform_cloud_connection_id: {
            type: "string",
            ref: "terraform_cloud_connection",
          },
        },
        attachments: {},
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  },
  {
    terraform_cloud_connection: {
      schema: {
        title: "terraform_cloud_connection",
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: {
          id: {
            type: "string",
            maxLength: 100,
          },
          updatedAt: {
            type: "number",
            minimum: 0,
            maximum: 1000000000000000,
            multipleOf: 1,
          },
          project_id: {
            type: "string",
            ref: "project",
          },
          organization_name: {
            type: "string",
          },
          apply_token: {
            type: "string",
          },
          organization_token: {
            type: "string",
          },
        },
        attachments: {},
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  },
  {
    project: {
      schema: {
        title: "project",
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: {
          id: {
            type: "string",
            maxLength: 100,
          },
          updatedAt: {
            type: "number",
            minimum: 0,
            maximum: 1000000000000000,
            multipleOf: 1,
          },
          name: {
            type: "string",
          },
          terraformCloudProjectId: {
            type: "string",
          },
        },
        attachments: {},
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  },
  {
    web_application: {
      schema: {
        title: "web_application",
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: {
          id: {
            type: "string",
            maxLength: 100,
          },
          updatedAt: {
            type: "number",
            minimum: 0,
            maximum: 1000000000000000,
            multipleOf: 1,
          },
          project_id: {
            type: "string",
            ref: "project",
          },
          name: {
            type: "string",
          },
          forms: {
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  maxLength: 100,
                },
                inputs: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      type: {
                        type: "string",
                      },
                      name: {
                        type: "string",
                      },
                    },
                  },
                },
              },
            },
            deployment: {
              type: "object",
              properties: {
                environment_name: {
                  type: "string",
                },
                is_github_actions: {
                  type: "boolean",
                },
                location: {
                  type: "string",
                },
                domain_name: {
                  type: "string",
                },
                public_image_name: {
                  type: "string",
                },
                branch: {
                  type: "string",
                  maxLength: 100,
                },
              },
            },
          },
        },
        attachments: {},
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  },
]

export default schemas
