import toDatabase from "../exampleDeepFormData"
import {
  addDatabaseProvider,
  addRXDBDatabase,
  addSchemaFile,
  addSynchronizedRXDBDatabase,
  generateIndexedDBDatabases,
  generateNodeJSDatabase,
} from "../databases/DatabaseService"
import {
  addFile,
  createFile,
  getReactFileHandle,
  writeFile,
} from "../file_system/FileSystemService"
import {
  addLoginForm,
  addSignUpForm,
  generateAllForms,
} from "../forms/FormService"
import BaseForm from "../forms/templates/BaseForm"
import { addCapitalizeFirstLetter } from "../helpers/HelperService"
import {
  addCheckboxInput,
  addDateInput,
  addDateTimeInput,
  addDropdownInput,
  addInputsController,
  addInputsTypeFile,
  addPackageFile,
  addPhoneNumberInput,
  addPostalCodeInput,
  addServerPackageFile,
  addTextInput,
  addTimeInput,
  handleInput,
} from "../inputs/InputService"
import { generateLayout, generateNavigationBar } from "../layouts/LayoutService"
import {
  stopMusic,
  getRandomMusicFromMyPlaylist,
} from "../services/musicService"
import { generateAllTables } from "../tables/TableService"
import { addFaviconTo, addLogoTo, generatePWA } from "../pwa/PwaService"
import {
  PublicDirectory,
  SynchronizerDirectory,
  WebAppDirectory,
} from "../file_system/Directories"
import {
  ProductionDockerFileForWebApplication,
  SynchronizerDockerfile,
} from "../templates/docker"
import { NginxConfigurationFile } from "../templates/nginx"
import {
  GithubActionWorkflowPullRequestBranchTemplate,
  addGithubActionWorkflow,
} from "../templates/github_actions"
import Html from "../pwa/templates/Html"

export const generateGithubActions = async (root_directory) => {
  await addGithubActionWorkflow(
    root_directory,
    GithubActionWorkflowPullRequestBranchTemplate
  )
}

export const generateGithubActionsForPullRequestEnvironment = async (
  project,
  webApplication,
  root_directory
) => {
  const workflowFile = GithubActionWorkflowPullRequestBranchTemplate.template(
    "codebot", // container_registry_resource_group_name
    "prod", // container_registry_resource_group_name_environment
    //webApplication.deployment.environment_name,
    "acrpd1m",
    webApplication.deployment.location,
    webApplication.deployment.port,
    webApplication.deployment.cpu_cores
  )

  await addGithubActionWorkflow(root_directory, workflowFile)
}

const getFormsInputTypes = (forms) => {
  // Add Inputs
  const allTypes = forms.map((form) => form.inputs.map((input) => input.type))
  // Step 3: Flatten the array
  const flattenedTypes = allTypes.flat()

  // Step 4: Filter out duplicates using Set
  const uniqueTypes = [...new Set(flattenedTypes)]

  return uniqueTypes
}

const addTitleToHtml = (rootDirectory, webApplication) =>
  PublicDirectory(rootDirectory).then((directory) => {
    createFile(directory, "index.html").then((file) =>
      writeFile(file, Html.template(webApplication.name))
    )
  })

export const generateAll = async (project, webApplication, root_directory) => {
  const webAppDirectory = await WebAppDirectory(root_directory)
  const synchronizerDirectory = await SynchronizerDirectory(root_directory)

  // Add PWA
  await generatePWA(webAppDirectory, project.logo, webApplication)

  addTitleToHtml(webAppDirectory, webApplication)
  addLogoTo(webAppDirectory, "logo.png", project.logo)
  addFaviconTo(webAppDirectory, project.logo)

  // Add Deployments
  await addPackageFile(project, webAppDirectory)
  await addServerPackageFile(project, synchronizerDirectory)
  await addFile(webAppDirectory, ProductionDockerFileForWebApplication)
  await addFile(webAppDirectory, NginxConfigurationFile, {
    domainName: "tron.bot",
  })
  await addFile(synchronizerDirectory, SynchronizerDockerfile)

  const inputsTypes = getFormsInputTypes(webApplication.forms)
  inputsTypes.forEach(
    async (inputType) => await handleInput(inputType, webAppDirectory)
  )

  //await addInputsTypeFile(webAppDirectory)
  // await addInputsController(webAppDirectory)

  // Add Forms, Tables, Routes and Pages
  await generateAllForms(webAppDirectory, webApplication.forms)
  await generateAllTables(webAppDirectory, webApplication.forms)
  await generateLayout(webAppDirectory, webApplication)

  // Add Database Server
  await generateIndexedDBDatabases(webAppDirectory, webApplication.forms)
  await generateNodeJSDatabase(
    webAppDirectory,
    synchronizerDirectory,
    webApplication.forms
  )
}

const commands = ({ setMessage, project }) => [
  {
    command: ["Hello world", "Hello", "Hi", "Sup"],
    callback: () => setMessage(`Hello User`),
  },
  {
    command: ["Who are you"],
    callback: () =>
      setMessage(
        `My name is TRON. I can assist you in coding React PWA Offline First applications. 
      I've been thought how to use { data: structures } to generate 
      - FrontEnd - Forms, Tables with Sort/Filter/Search 
      - Backend - Synchronized via GraphQl with Frontend Rxdb(database)
      - All tested through the fucking roof: Tests counts: 0... 
    `
      ),
  },
  {
    command: ["How are you?"],
    callback: () => setMessage(`I am.`),
  },
  {
    command: ["Can you play music", "Do you play music", "Do you know music"],
    callback: () => {
      setMessage(`I do not know many songs, but here's one I quite appreciate.`)
      getRandomMusicFromMyPlaylist()
    },
  },
  {
    command: ["Stop the music", "Stop music"],
    callback: () => {
      setMessage(`Ok... This was my favorite part... but ok...`)
      stopMusic()
    },
  },
  // {
  //   command: ["create a component called :name"],
  //   callback: (name) =>
  //     sendCommand({
  //       command: "createComponent",
  //       parameters: { name: `${name.toLowerCase()}` },
  //     }),
  // },
  {
    command: ["Generate All", "generate all"],
    callback: async () => {
      generateAll(project)
      setMessage("I generated all i could find")
    },
  },
  {
    command: ["Generate Navigation Bar"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.85,
    callback: async () => {
      generateNavigationBar(project.frontend_directory, toDatabase)
      setMessage("Navigation Bar Generated")
    },
  },
  {
    command: ["Generate all forms"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.9,
    callback: async () => {
      generateAllForms(project.frontend_directory, toDatabase)
      setMessage("All Forms Generated")
    },
  },
  {
    command: [
      "Generate all tables",
      "Generate All Tables",
      "generate all tables",
      "generate all table",
    ],
    callback: async () => {
      generateAllTables(project.frontend_directory, toDatabase)
      setMessage("Tables Generated")
    },
  },
  {
    command: ["Generate Indexed Database"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.8,
    callback: async () => {
      await generateIndexedDBDatabases(project.frontend_directory, toDatabase)
      setMessage("IndexedDB Database Generated")
    },
  },
  {
    command: ["Add a login form", "Add login form", "login"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.7,
    callback: async () => {
      await addTextInput(project.frontend_directory)
      await addLoginForm(project.frontend_directory)
      await addCapitalizeFirstLetter(project.frontend_directory)
      setMessage("Login Form Created")
    },
  },
  {
    command: ["Add a Sign Up form", "Add Sign Up form", "Sign Up"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.7,
    callback: async () => {
      await addTextInput(project.frontend_directory)
      await addPhoneNumberInput(project.frontend_directory)
      await addPostalCodeInput(project.frontend_directory)
      await addDropdownInput(project.frontend_directory)
      await addCheckboxInput(project.frontend_directory)
      await addSignUpForm(project.frontend_directory)
      await addCapitalizeFirstLetter(project.frontend_directory)
      setMessage("Sign Up Form Created")
    },
  },
  {
    command: ["Add Form"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.7,
    callback: async () => {
      // Now obsolete -- no longer passing hard coded formData -- need to find a UI way to use this
      const fileHandle = await getReactFileHandle()

      // importing InputsController currently requires importing all of its dependencies
      // ... all the inputs... -_-
      await addInputsController(project.frontend_directory)
      await addTextInput(project.frontend_directory)
      await addPhoneNumberInput(project.frontend_directory)
      await addPostalCodeInput(project.frontend_directory)
      await addDropdownInput(project.frontend_directory)
      await addCheckboxInput(project.frontend_directory)
      await addDateInput(project.frontend_directory)
      await addDateTimeInput(project.frontend_directory)
      await addTimeInput(project.frontend_directory)

      const name = "toto" // ActivityFormData({}).name.concat('Form')
      const form = BaseForm(name)
      await writeFile(fileHandle, form)
      setMessage("Form Created")
    },
  },
  {
    command: ["Add Frontend Database"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.8,
    callback: async () => {
      // Now obsolete -- no longer passing hard coded formData -- need to find a UI way to use this
      await addSchemaFile(project.frontend_directory, [{}]) // ActivityFormData({})
      await addRXDBDatabase(project.frontend_directory)
      await addDatabaseProvider(project.frontend_directory)

      setMessage("Frontend Database Created")
    },
  },
  {
    command: ["Add Postgre SQL Database", "Add Postgre Database"],
    isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.5,
    callback: async () => {
      // Now obsolete -- no longer passing hard coded formData -- need to find a UI way to use this
      //await addSchemaFile(project.frontend_directory, ActivityFormData({}))
      await addSynchronizedRXDBDatabase(project.frontend_directory)
      await addDatabaseProvider(project.frontend_directory)

      //const serverproject.frontend_directory = await addGraphQLReplicationToPostgresql(ActivityFormData({}))

      //reloadDirectory(serverproject.frontend_directory)
      setMessage("Synchronized Frontend Database with PostgreSQL using GraphQL")
    },
  },
  {
    command: ["Add Text Input", "Add a Text Input"],
    callback: async () => {
      await addTextInput(project.frontend_directory)
      setMessage(`Input Created`)
    },
  },
  // {
  //   command: "*", // This is a fallback command
  //   callback: async (text) => {
  //     const completion = await openai.createCompletion({
  //       model: "text-davinci-003",
  //       prompt:
  //         "/* I need a basic terraform configuration for a small azure cpu instance. */",
  //       temperature: 0.7,
  //       max_tokens: 1000,
  //     })
  //     debugger
  //     setMessage(completion.data.choices[0].text)
  //   },
  // },
]

export default commands
