import { ThemeProvider } from "@mui/material"
import { appTheme } from "./common/layout/theme"
import DatabaseProvider from "./database/DatabaseProvider"
import RouterProvider from "./router/RouterProvider"
import Root from "./router/Root"
import router from "./router/Router"
import { createContext, useReducer } from "react"
import { initialState, AppReducer } from "./AppReducer"

export const AuthContext = createContext()

function App() {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <DatabaseProvider>
        <ThemeProvider theme={appTheme}>
          <RouterProvider router={router()}>
            <Root />
          </RouterProvider>
        </ThemeProvider>
      </DatabaseProvider>
    </AuthContext.Provider>
  )
}

export default App
