import { ComonComponentsInputDirectory } from "../file_system/Directories"
import { writeFile, createFile } from "../file_system/FileSystemService"
import TextInput from "./templates/TextInput"
import PhoneNumberInput from "./templates/PhoneNumberInput"
import PostalCodeInput from "./templates/PostalCodeInput"
import DropdownInput from "./templates/DropdownInput"
import CheckboxInput from "./templates/CheckboxInput"
import DateInput from "./templates/DateInput"
import DateTimeInput from "./templates/DateTimeInput"
import ImageInput from "./templates/ImageInput"
import InputsController from "./templates/InputsController"
import TimeInput from "./templates/TimeInput"
import InputTypes from "./templates/Inputs"
import Inputs from "./Inputs"
import DirectoryInput from "./templates/DirectoryInput"
import WebAppPackages from "./templates/WebAppPackages"
import ServerPackages from "./templates/ServerPackages"

export const createInputs = async (inputTypes, directoryHandle) => {
  inputTypes.forEach((type) => {
    createInput(getInputTemplate(type), directoryHandle)
  })
}

const createInput = async (input, directoryHandle) => {
  await ComonComponentsInputDirectory(directoryHandle)
    .then((directory) => createFile(directory, `${input.name}.jsx`))
    .then((fileHandle) => writeFile(fileHandle, input.template()))

  return directoryHandle
}

const getInputTemplate = (type) => {
  switch (type) {
    case Inputs.Checkbox:
      return CheckboxInput
    case Inputs.Date:
      return DateInput
    case Inputs.DateTime:
      return DateTimeInput
    case Inputs.Directory:
      return DirectoryInput
    case Inputs.Dropdown:
      return DropdownInput
    case Inputs.Image:
      return ImageInput
    case Inputs.PhoneNumber:
      return PhoneNumberInput
    case Inputs.PostalCode:
      return PostalCodeInput
    case Inputs.Text:
      return TextInput
    case Inputs.Time:
      return TimeInput
    default:
      return "Null"
  }
}

const createJsFile = async (file, directoryHandle) => {
  await ComonComponentsInputDirectory(directoryHandle)
    .then((directory) => createFile(directory, `${file.name}.js`))
    .then((fileHandle) => writeFile(fileHandle, file.template()))

  return directoryHandle
}

const createPackageJsonFileInRootDirectory = async (
  file,
  projectName,
  directoryHandle
) => {
  createFile(directoryHandle, `package.json`).then((fileHandle) =>
    writeFile(fileHandle, file.template(projectName))
  )

  return directoryHandle
}

export const addPackageFile = async (project, frontendDirectory) =>
  await createPackageJsonFileInRootDirectory(
    WebAppPackages,
    project.name,
    frontendDirectory
  )

export const addServerPackageFile = async (project, serverDirectory) =>
  await createPackageJsonFileInRootDirectory(
    ServerPackages,
    project.name,
    serverDirectory
  )

export const addInputsTypeFile = async (directoryHandle) =>
  await createJsFile(InputTypes, directoryHandle)

export const addInputsController = async (directoryHandle) =>
  await createJsFile(InputsController, directoryHandle)

export const addTextInput = async (directoryHandle) =>
  await createInput(TextInput, directoryHandle)

export const addPhoneNumberInput = async (directoryHandle) =>
  await createInput(PhoneNumberInput, directoryHandle)

export const addPostalCodeInput = async (directoryHandle) =>
  await createInput(PostalCodeInput, directoryHandle)

export const addDropdownInput = async (directoryHandle) =>
  await createInput(DropdownInput, directoryHandle)

export const addCheckboxInput = async (directoryHandle) =>
  await createInput(CheckboxInput, directoryHandle)

export const addDateInput = async (directoryHandle) =>
  await createInput(DateInput, directoryHandle)

export const addDateTimeInput = async (directoryHandle) =>
  await createInput(DateTimeInput, directoryHandle)

export const addTimeInput = async (directoryHandle) =>
  await createInput(TimeInput, directoryHandle)

export const addImageInput = async (directoryHandle) =>
  await createInput(ImageInput, directoryHandle)

// InputController to map input types to their respective functions
const InputController = {
  [Inputs.Checkbox]: addCheckboxInput,
  [Inputs.Date]: addDateInput,
  [Inputs.DateTime]: addDateTimeInput,
  [Inputs.Dropdown]: addDropdownInput,
  [Inputs.PhoneNumber]: addPhoneNumberInput,
  [Inputs.PostalCode]: addPostalCodeInput,
  [Inputs.Text]: addTextInput,
  [Inputs.Time]: addTimeInput,
  [Inputs.Image]: addImageInput,
}

// Function to handle input based on type
export async function handleInput(inputType, webAppDirectory) {
  const action = InputController[inputType]
  if (action) {
    await action(webAppDirectory)
  } else {
    console.log(`No handler for input type: ${inputType}`)
  }
}
