export const SynchronizerDockerfile = {
  name: "Dockerfile",
  template: () => `# syntax=docker/dockerfile:1
FROM node:18-alpine

WORKDIR /synchronizer

COPY package.json .
COPY yarn.lock .
RUN yarn
COPY . .


EXPOSE 3101

CMD ["yarn", "start"]`,
}

export const ProductionDockerFileForWebApplication = {
  name: "Dockerfile.prod",
  template: () => `FROM node:18-buster as builder

  WORKDIR /web
  
  COPY package.json .
  COPY yarn.lock .
  RUN yarn --network-timeout 100000
  
  COPY . .
  
  RUN yarn build:prod
  
  FROM nginx:1.17
  COPY --from=builder /web/build /usr/share/nginx/html
  COPY /nginx.conf /etc/nginx/nginx.conf
  EXPOSE 80
  CMD ["nginx", "-g", "daemon off;"]`,
}
