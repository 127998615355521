import { CapitalizeFirstLetter } from "../../../forms/FormHelpers"

export const RootValueHelper = () => RootValueHelperTemplate

const RootValue = (form) => {
  const capitalizedName = CapitalizeFirstLetter(form.name)
  return `import { pubsub } from "../../index.js";
import { client } from "../database.js";
import { authenticateRequest, log, sortByUpdatedAtAndPrimary, validateBearerToken } from "./RootValueHelpers.js";
import { lastOfArray } from 'rxdb';

let documents = [];

const ${form.name} = {
  pull${capitalizedName}: async (args, request) => {
    log('## pull${capitalizedName}()');
    log(args);
    authenticateRequest(request);

    const lastId = args.checkpoint ? args.checkpoint.id : '';
    const minUpdatedAt = args.checkpoint
      ? args.checkpoint.updatedAt
      : 0;

    try {
      const res = await client.query("SELECT * FROM ${
        form.name
      } WHERE updatedAt > ".concat(minUpdatedAt))
      if (res.rows.length > 0) documents.push(...res.rows.map(row => ({...row, deleted: false})))
    } catch (err) {
      throw new Error("Failed to pull ${form.name} from database")
    }

    const sortedDocuments = documents.sort(sortByUpdatedAtAndPrimary);

    // only return where updatedAt >= minUpdatedAt
    const filterForMinUpdatedAtAndId = sortedDocuments.filter((doc) => {
      if (!args.checkpoint) return true
      if (doc.updatedAt < minUpdatedAt) return false
      if (doc.updatedAt > minUpdatedAt) return true
      if (doc.updatedAt === minUpdatedAt) {
        if (doc.id > lastId) return true
        else return false
      }
    })

    const limitedDocs = filterForMinUpdatedAtAndId.slice(0, args.limit);
    const last = lastOfArray(limitedDocs);
    const ret = {
      documents: limitedDocs,
      checkpoint: last
        ? {
            id: last.id,
            updatedAt: last.updatedat, // careful updatedat without camelCast // was not able to support camelCase with postgreSql
          }
        : {
            id: lastId,
            updatedAt: minUpdatedAt,
          },
    };

    pubsub.publish("stream${capitalizedName}:", {
      stream${capitalizedName}: ret,
    })

    console.log('pull${capitalizedName}() ret:');
    console.log(JSON.stringify(ret, null, 4));
    return ret;
  },
  push${capitalizedName}: (args, request) => {
    log('## push${capitalizedName}()');
    log(args);
    authenticateRequest(request);

    const rows = args.${form.name}PushRow;
    const conflicts = [];
    const writtenDocs = [];

    let lastCheckpoint = {
      id: '',
      updatedAt: 0,
    };

    rows.forEach((row) => {
      const docId = row.newDocumentState.id;
      const docCurrentMaster = documents.find((d) => d.id === docId);

      if (
        docCurrentMaster &&
        row.assumedMasterState &&
        docCurrentMaster.updatedAt !==
          row.assumedMasterState.updatedAt
      ) {
        conflicts.push(docCurrentMaster)
        return
      }

      const doc = row.newDocumentState;
      documents = documents.filter((d) => d.id !== doc.id);
      documents.push(doc);

      lastCheckpoint.id = doc.id;
      lastCheckpoint.updatedAt = doc.updatedAt;
      writtenDocs.push(doc);

      // add to POSTGRESQL Database
      try {
        client.query(
          "INSERT INTO ${form.name} (updatedAt, ${form.inputs
    .map((input) => input.name)
    .join(", ")}) VALUES (${form.inputs
    .reduce((acc, _, index) => acc.concat(`$${index + 1}`), [])
    .concat(`$${form.inputs.length + 1}`)
    .join(", ")}) RETURNING *", 
          [ doc.updatedAt, ${form.inputs
            .map((input) => `doc.${input.name}`)
            .join(", ")}]
        )
      } catch (err) {
        throw new Error("Failed to insert new ${form.name}")
      }
    });

    pubsub.publish('stream${capitalizedName}:', {
      stream${capitalizedName}: {
          documents: writtenDocs,
          checkpoint: lastCheckpoint,
        },
    });

    console.log('## current documents:');
    console.log(JSON.stringify(documents, null, 4));
    console.log('## conflicts:');
    console.log(JSON.stringify(conflicts, null, 4));

    return conflicts;
  },
  stream${capitalizedName}: (args) => {
    log('## stream${capitalizedName}:()');

    console.dir(args);
    const authHeaderValue = args.headers.Authorization;
    const bearerToken = authHeaderValue.split(' ')[1];

    validateBearerToken(bearerToken);

    return pubsub.asyncIterator('stream${capitalizedName}:');
  },
}

export default ${form.name}
`
}

const RootValueHelperTemplate = `
export const log = (msg) => {
  const prefix = '# GraphQL Server: ';
  if (typeof msg === 'string') {
      console.log(prefix + msg);
  } else {
      console.log(prefix + JSON.stringify(msg, null, 2));
  }
}

export const sortByUpdatedAtAndPrimary = (a, b) => {
  if (a.updatedAt > b.updatedAt) return 1;
  if (a.updatedAt < b.updatedAt) return -1;

  if (a.updatedAt === b.updatedAt) {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      else return 0;
  }
}

/**
 * Returns true if the request is authenticated
 * throws if not.
 * In a real world app you would parse and validate the bearer token.
 * @link https://graphql.org/graphql-js/authentication-and-express-middleware/
 */
 export const authenticateRequest = (request) => {
  const authHeader = request.header('authorization');
  const splitted = authHeader.split(' ');
  const token = splitted[1];
  validateBearerToken(token);
}

export const validateBearerToken = (token) => {
  //if (token === JWT_BEARER_TOKEN) {
      return true;
  // } else {
  //     console.log('token not valid ' + token);
  //     throw new Error('not authenticated');
  // }
}
`

export default RootValue
