import Inputs from "../inputs/Inputs"

const defaultProperties = {
  id: {
    type: "string",
    maxLength: 100,
  },
  updatedAt: {
    type: "number",
    minimum: 0,
    maximum: 1000000000000000,
    multipleOf: 1,
  },
}

const getPropertyType = (property) => {
  switch (property.type) {
    case Inputs.Checkbox:
      return "boolean"
    default:
      return "string"
  }
}

const buildProperties = (properties) =>
  properties.reduce((acc, property) => {
    return {
      ...acc,
      [property.name]: {
        type: getPropertyType(property),
      },
    }
  }, defaultProperties)

export const buildRXDBSchema = (formData) => {
  const conditionalProperties = {}

  if (isAttachmentsRequired(formData.inputs))
    conditionalProperties.attachments = {}

  return {
    [`${formData.name.toLowerCase()}`]: {
      schema: {
        title: formData.name,
        version: 0,
        type: "object",
        primaryKey: "id",
        properties: buildProperties(formData.inputs.filter(isPropertyRequired)),
        ...conditionalProperties,
      },
      checkpointFields: ["id", "updatedAt"],
      deletedField: "deleted",
    },
  }
}

const isPropertyRequired = (input) => input.type !== Inputs.Image

// where should this really be?
export const isAttachmentsRequired = (inputs) =>
  inputs.filter((input) => input.type === Inputs.Image).length > 0
