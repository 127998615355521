const CheckboxInput = {
  name: "CheckboxInput",
  template: () => `import * as React from "react"
  import FormControlLabel from "@mui/material/FormControlLabel"
  import Checkbox from "@mui/material/Checkbox"
  import { Controller } from "react-hook-form"
  
  const CheckboxInput = ({ name, label, control }) => {
    return (
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                onChange={(e) => field.onChange(e.target.checked)}
                required
              />
            )}
          />
        }
        label={label}
      />
    )
  }
  
  export default CheckboxInput  
`,
}

export default CheckboxInput
