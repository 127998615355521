const ServerPackages = {
  name: "ServerPackages",
  // dependencies: ["@material-ui/core@^4.12.4", "react"],
  template: (name) => `{
  "name": "${name.toLowerCase()}-synchronizer",
  "version": "1.0.0",
  "description": "GraphQL Replication Server",
  "main": "index.js",
  "scripts": {
    "start": "node index.js",
    "build": "react-scripts build",
    "test": "react-scripts test",
    "eject": "react-scripts eject",
    "server": "node ./server"
  },
  "author": "",
  "license": "",
  "type": "module",
  "dependencies": {
    "cors": "^2.8.5",
    "dotenv": "^16.0.3",
    "express": "^4.18.2",
    "express-graphql": "^0.12.0",
    "graphql": "^16.6.0",
    "graphql-subscriptions": "^2.0.0",
    "pg": "^8.8.0",
    "rxdb": "13.8.3",
    "rxjs": "^7.5.7"
  }
}    
`,
}

export default ServerPackages
