const PostalCodeInput = {
  name: 'PostalCodeInput',
  template: () => `import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";

const PostalCodeInput = ({ errors, label, name, register }) => {
  const [value, setValue] = useState()

  return (
    <>
      <ReactInputMask
        mask="a9a 9a9"
        value={value}
        disabled={false}
        maskChar=" "
        onChange={(event) => setValue(event.target.value)}
        {...register(name, { required: true })}
      >
        {(inputProps) => 
          <TextField
            {...inputProps}
            error={errors ? true : false}
            fullWidth
            label={label}
            placeholder={label}
            required
            type="tel"
            variant="outlined"
          />
        }
      </ReactInputMask>
    </>
  );
};

export default PostalCodeInput;
`
}

export default PostalCodeInput