import { CapitalizeFirstLetter } from "../../forms/FormHelpers"

const BasePage = (page) => {
  const capitalizedName = CapitalizeFirstLetter(page.name)
  return `import { AppBar, Toolbar, Typography } from "@mui/material"

const ${capitalizedName}Page = () => {

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          ${capitalizedName}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default ${capitalizedName}Page`
}

export default BasePage
